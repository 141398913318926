.contract-table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

.contract-table th,
.contract-table td {
  border: solid 1px #4a4a4a;
}

.contract-table .title {
  height: 60px;
  font-size: 22px;
  font-weight: 900;
}

.contract-table .credit-info {
  padding: 22px 30px;
}

.contract-table .credit-info div {
  float: left;
  text-align: left;
  width: 50%;
}

.contract-table .credit-info div.odd {
  text-align: right;
}

.contract-table .credit-summary,
.contract-table .credit-summary--mobile {
  display: table-row;
}

.contract-table .credit-summary td,
.contract-table .credit-summary--mobile td {
  line-height: 30px;
  padding: 15px 30px;
  height: 90px;
  width: 20%;
}

.contract-table .credit-summary td span,
.contract-table .credit-summary--mobile td span {
  display: block;
}

.contract-table .credit-summary--mobile {
  display: none;
}

.contract-table .terms-info td {
  padding: 15px 30px;
}

.contract-table .text td {
  padding: 25px 30px;
  text-align: justify;
}

.contract-table .line {
  border: solid 1px #4a4a4a;
  height: 2px;
  margin: 113px auto 10px;
  width: 300px;
}

.contract-table .loan-user-name {
  text-align: center;
}

.contract-table .label {
  font-weight: 900;
  margin-right: 2px;
}

@media screen and (max-width: 900px) {
  .contract-table .title {
    height: 93px;
    font-size: 18px;
    padding: 0 20px;
  }
  .contract-table .credit-info {
    padding: 15px;
  }
  .contract-table .credit-info div {
    float: none;
    width: 100%;
  }
  .contract-table .credit-info div.odd {
    text-align: left;
  }
  .contract-table .credit-summary td {
    padding: 15px;
  }
  .contract-table .credit-summary td span {
    display: block;
  }
  .contract-table .terms-info td {
    padding: 15px;
  }
  .contract-table .text td {
    padding: 15px;
  }
}

@media screen and (max-width: 480px) {
  .contract-table .title {
    font-size: 16px;
  }
  .contract-table .credit-summary {
    display: none;
  }
  .contract-table .credit-summary--mobile {
    display: table-row;
  }
  .contract-table .credit-summary--mobile table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
  }
  .contract-table .credit-summary--mobile td {
    line-height: 1.43;
    padding: 15px 30px;
    height: 0px;
  }
  .contract-table .credit-summary--mobile td.no-padding {
    padding: 0;
  }
  .contract-table .credit-summary--mobile td.no-borders {
    border: none;
    width: 50%;
  }
  .contract-table .credit-summary--mobile td.no-borders.left {
    border-left: solid 1px #4a4a4a;
  }
}

@media screen and (max-width: 320px) {
  .contract-table .title {
    height: 83px;
    padding: 5px;
  }
  .contract-table .credit-info {
    padding: 5px;
  }
  .contract-table .credit-summary td {
    padding: 5px;
  }
  .contract-table .terms-info td {
    padding: 5px;
  }
  .contract-table .text td {
    padding: 5px;
  }
}
