@import "variables";
.step-form {
  margin: 0 auto;
  padding: 15px 0;
  min-height: 400px;

  h1,
  h2 {
    margin-top: 0;
    text-align: center;
    line-height: 1.1;
    color: $primary-color;
    font-weight: 300;
  }

  ul {
    padding: 0;
  }

  a {
    cursor: pointer;
    text-decoration: underline;
  }

  p {
    font-size: 16px;
  }

  .label-email {
    text-align: left;
    color: $base-gray;
    font-size: 14px;
    font-weight: 900;
  }

  .error {
    text-align: center;
    color: $error-color;
  }

  .btn-next {
    min-width: 170px;
  }

  .error-text {
    text-align: left;
    font-size: 14px;

    ul {
      margin-top: 0;
      margin-left: 2em;
    }
  }

  .error-text-clabe {
    position: absolute;
    margin-left: 34px;
  }

  .invalid {
    color: $error-color;
  }

  .inputs-container {
    margin: 34px 0 0;
    text-align: center;
  }

  .input-container {
    label {
        display: block;
        transition: top .4s ease, font-size .4s ease;
        min-height: 1em;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        pointer-events: none;
    }
  }

  .block-options-container {
    margin: 40px 0 55px;
  }

  .block-option {
    display: inline-block;
    transition: border-color .2s ease, color .2s ease;
    margin: 15px;
    border: solid 2px transparent;
    border-radius: 2px;
    box-shadow: 0 3px 16px 0 rgba(0, 0, 0, .1);
    background-color: $white;
    cursor: pointer;
    padding: 10px 58px;
    width: 300px;
    height: 100px;
    text-align: center;
    color: $base-gray;
    font-size: 18px;
    font-weight: 300;

    &:focus {
      border-radius: 2px;
      border-color: rgba($secondary-color, .5);
      box-shadow: 0 3px 16px 0 rgba($secondary-color, .1);
      color: rgba($secondary-color, .5);
      font-weight: bold;
    }

    &.active,
    &:hover {
      border-radius: 2px;
      border-color: $secondary-color;
      box-shadow: 0 3px 16px 0 rgba($secondary-color, .1);
      color: $secondary-color;
      font-weight: bold;
    }

    & small {
      display: block;
      font-size: 14px;
    }
  }

  .input-field {
    display: inline-block;
    float: none;
    text-align: left;
  }

  .container-btn-next {
    display: inline-block;
    margin-left: 10px;
    margin-top: 15px;
  }

  .forgot-password {
    margin: 5em 0 1em;
    text-align: center;
  }

  .mati-button-container {
    display: inherit;
    margin: 15% auto;
    width: 300px;

    & button {
      & [type='submit'] {
        border: 0;
        background-color: transparent !important;
      }
    }
  }

  &.step-01,
  &.step-05,
  &.step-06
  &.front-id-picture {
    max-width: 780px;

    img {
      cursor: pointer;
    }
  }

  &.step-03 {
    .block-option {
      padding: 10px 0;
    }
  }

  &.step-04 {
    .info {
      margin: 0 auto;
      max-width: 780px;
    }

    .table-summary-container {
      margin: 30px 0;
    }

    .profeco-text {
      text-align: justify;
      font-size: 16px;
      font-weight: 900;
    }

    .table-component {
      font-size: 12px;

      .description {
        float: left;
        width: 50%;
      }

      .value {
        float: left;
        width: 50%;
        color: $base-gray;
        font-weight: 900;
      }
    }
  }

  &.step-06,
  &.front-id-picture {
    min-height: 650px;

    .camera-input-container {
      padding-top: 20px;
    }

    h1 {
      max-width: 780px;
      margin: 0 auto;
    }

    h2 {
      font-size: 26px;
    }

    .id-selector{
      cursor: pointer;
      color: $secondary-color;
      border: solid 2px $secondary-color;
      border-radius: 10px;
      font-weight: 900;
      width: 320px;
      margin: 0 auto 30px;
      text-align: left;

      .active {
        background-color: $secondary-color;
        color: $white;
      }

      .option {
        text-align: center;
        padding: 10px 0;
        display: inline-block;
        width: 50%;
      }
    }
  }

  .passport-dropdown {
    max-width: 220px;
    margin: 0 auto;
  }

  &.step-07 {
    padding-top: 200px;
    max-width: 964px;

    h1 {
      line-height: 45px;
    }

    h2 {
      line-height: 45px;
      color: inherit;
      font-size: 24px;
    }

    .contract-summary {
      text-align: justify;

      span {
        font-weight: 800;
      }

      ul {
        list-style: none;

        li {
          margin-bottom: 15px;

          &::before {
            display: inline-block;
            position: absolute;
            top: 8px;
            left: -15px;
            border-top: solid transparent;
            border-bottom: solid transparent;
            border-left: solid $secondary-color;
            border-width:	5px;
            width: 0;
            height: 0;
            content: ' ';
          }
        }
      }

      .acceptance {
        text-align: center;
        color: $primary-color;
        font-size: 20px;
      }
    }
  }

  &.step-08 {
    max-width: 1170px;
    text-align: center;
    color: $darker-gray;
    font-family: Avenir;
    font-size: 16px;

    .contract-details {
      font-size: 16px;
      margin-top: 30px;
      text-align: justify;

      & b {
        font-weight: 900;

        &.underline {
          text-decoration: underline;
        }
      }

      .index {
        font-weight: 900;
        text-align: left;

        & span {
          display: block;
        }
      }

      .clauses {
        .client-label {
          margin-top: 30px;
          text-align: center;
        }

        .line {
          border: solid 1px $darker-gray;
          height: 2px;
          margin: 113px auto 10px;
          width: 300px;
        }

        .loan-user-name {
          margin-bottom: 30px;
          text-align: center;
        }
      }

      & h4,
      & h3 {
        font-size: 16px;
        font-weight: 900;
        line-height: 1.5;
        text-align: center;
      }

      & h3 {
        font-size: 20px;
        margin: 30px 0 24px;
      }
    }
  }

  &.step-pagare {
    padding-top: 120px;
    text-align: justify;
    color: $darker-gray;

    .today {
      margin-bottom: 15px;
      text-align: right;
      font-size: 16px;
      font-weight: 900;
    }

    .title {
      margin-bottom: 40px;
      text-align: center;
      font-size: 22px;
      font-weight: 900;
    }

    .subtitle {
      margin-bottom: 40px;
      font-weight: 900;
    }

    .last-paragraph {
      margin-bottom: 50px;
    }

    .accept {
      margin-bottom: 20px;
      font-size: 16px;
      font-weight: 900;
    }

    .line {
      margin: 50px auto 10px;
      border: solid 1px $darker-gray;
      width: 300px;
      height: 2px;
    }

    .loan-user-name {
      margin-bottom: 30px;
      text-align: center;
      font-size: 16px;
    }

    p {
      margin-block-end: 25px;
    }

    span {
      font-weight: 900;
    }
  }

  &.step-sign {
    max-width: 970px;

    & h2 {
      margin: 120px auto 15px;
      max-width: 770px;
    }

    .btn-container {
      margin-top: 40px;
      text-align: center;
    }

    .sing-inside-square {
      font-size: 24px;
      text-align: center;
    }
  }

  &.step-08,
  &.step-direct-debit {
    padding-top: 50px;
  }

  &.step-direct-debit {
    color: $darker-gray;
    font-family: Avenir;
    font-size: 16px;
    max-width: 1170px;

    & h3 {
      font-size: 22px;
      font-weight: 900;
      margin: 0 auto 20px;
      text-align: center;
      width: 380px;
    }

    & h5 {
      font-size: 20px;
      font-weight: normal;
      line-height: 1.5;
      margin-top: 0;
      text-align: center;
    }

    & p {
      line-height: 1.5;
      text-align: justify;
    }

    & b {
      font-weight: 900;
    }

    & ol {
      max-width: 970px;
      line-height: 2.5;
      padding-left: 85px;
      text-align: justify
    }

    .date {
      text-align: right;
    }

    .signature {
      text-align: center;
      margin-top: 50px;

      & p {
        text-align: center;
      }

      .line {
        margin: 80px auto 1px;
        border: solid 1px $darker-gray;
        width: 300px;
        height: 2px;
      }
    }
  }

  &.step-10 {
    max-width: 970px;
    text-align: center;

    & p {
      line-height: 28px;
      font-size: 20px;
    }

    & b,
    .mailto {
      font-weight: bold;
    }

    & img {
      margin-bottom: 25px;
    }
  }
}

@media screen and (max-width: $screen-sm) {
  .step-form {
    h1 {
      margin-bottom: 30px;
      font-size: 28px;
    }

    &.step-01,
    &.step-05 {
      :global(.col-4) {
        width: 47%;
      }
    }

    &.step-04 {
      .advice {
        display: none;
      }

      .credit-summary {
        .summary-info {
          width: 100%;

          &.lg {
            display: none;
          }

          &.sm {
            display: block;
          }

          .description {
            padding-right: 28px;
            text-align: right;
          }

          .value {
            padding-right: 0;
            text-align: left;
          }
        }
      }

      .table-summary-container {
        margin: 30px 0;
      }

      .inputs-container {
        margin: 30px 0;
      }

      .table-component {
        font-size: 14px;

        .description {
          padding-right: 10px;
          text-align: left;
        }

        .value {
          text-align: right;
        }
      }
    }

    &.step-07 {
      padding-top: 120px;
      max-width: 964px;

      h1 {
        line-height: inherit;
      }

      h2 {
        line-height: inherit;
        font-size: inherit;
      }

      .contract-summary {
        ul {
          li {
            &::before {
              top: 5px;
            }
          }
        }

        .acceptance {
          text-align: center;
          color: $primary-color;
          font-size: $font-size-h2-mobile;
        }
      }
    }

    &.front-id-picture {
      h1 {
        max-width: 200px;
      }

      h2 {
        font-size: 18px;
      }
    }

    &.step-step-sign {

      & h2 {
        position: absolute;
        top: 25px;
        margin: 0 auto;
        padding: 0 30%;
        width: 100%;
        font-size: 12px;
      }

      .btn-container {
        margin-top: 15px;
        text-align: center;
      }
    }

    &.step-10 {

      & p {
        font-size: 18px;
      }
    }
  }
}

@media screen and (max-width: $screen-xs) {
  .step-form {
    margin: 0 20px;

    h1 {
      margin-bottom: 20px;
      font-size: $font-size-h1-mobile;
    }

    h2 {
      line-height: 1.67;
      font-size: $font-size-h2-mobile;
    }

    ul {
      padding: 0;
    }

    [class*='col-'] {
      width: 100% !important;
    }

    .error-text-clabe {
      margin-left: 0;
    }

    .btn-next {
      margin-top: 15px;
      min-width: 98%;
      height: 75px;
      font-size: $button-font-size-mobile;
    }

    .inputs-container {
      margin: 30px 0;
    }

    .container-btn-next {
      display: block;
      margin-top: 100px;
      margin-left: 0;
    }

    .block-option {
      margin: 15px auto;
    }

    .col-12 {
        width: 100%;
    }

    &.step-04 {
      .credit-summary {
        .summary-info {
          .description {
            text-align: left;
          }
        }
      }
    }

    &.step-05 {
      .bank {
        margin-top: 30px;
      }
    }

    &.step-08 {
      font-size: 12px;
      margin: 0;

      .contract-details {
        font-size: 12px;

        & p {
          font-size: 12px;
        }

        & h4,
        & h3 {
          font-size: 14px;
          margin: 20px 0 10px;
        }

        .clauses {
          & p {
            font-size: 11px;
          }
        }
      }
    }

    &.step-sign {
      margin: 0 18px;

      & h2 {
        margin: inherit;
      }

      .sing-inside-square {
        font-size: 14px;
      }
    }

    &.step-08,
    &.step-direct-debit {
      padding-top: 120px;
    }

    &.step-direct-debit {
      font-size: 14px;

      & h3 {
        font-size: 16px;
        font-weight: 900;
        margin: 10px auto 20px;
        width: 79%;
      }

      & h5 {
        font-size: 16px;
      }

      & ol {
        padding-left: 10px;
      }

      .date {
        text-align: center;
      }

      .signature {
        text-align: center;
        margin-top: 30px;

        & p {
          text-align: center;
        }

        .line {
          margin: 60px auto 1px;
          border: solid 1px $darker-gray;
          width: 300px;
          height: 2px;
        }
      }
    }
    
    &.step-06,
    &.front-id-picture {
      min-height: 530px;
    }
  }
}

@media screen and (max-width: $screen-iphone-small) {
  .step-form {
    .block-option {
      padding: 10px 30px;
      width: 270px;
    }

    &.step-08 {
      margin-left: -2px;
    }

    &.step-sign {
      margin: 0 auto;

      & h2 {
        padding: 0;
      }
    }
  }
}

@media screen and (min-width: $screen-iphone-small) {
  :global(.col-4-ns) {
    .col-4-ns {
        width: 33.33333%;
    }
  }
}

.contract-table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;

  & th,
  & td {
    border: solid 1px $darker-gray;
  }

  .title {
    height: 60px;
    font-size: 22px;
    font-weight: 900;
  }

  .credit-info {
    padding: 22px 30px;
    div {
      float: left;
      text-align: left;
      width: 50%;

      &.odd {
        text-align: right;
      }
    }
  }

  .credit-summary,
  .credit-summary--mobile {
    display: table-row;
    & td {
      line-height: 30px;
      padding: 15px 30px;
      height: 90px;
      width: 20%;

      & span {
        display: block;
      }
    }
  }

  .credit-summary--mobile {
    display: none;
  }

  .terms-info {
    & td {
      padding: 15px 30px;
    }
  }

  .text {
    & td {
      padding: 25px 30px;
      text-align: justify;
    }
  }

  .line {
    border: solid 1px $darker-gray;
    height: 2px;
    margin: 113px auto 10px;
    width: 300px;
  }

  .loan-user-name {
    text-align: center;
  }

  .label {
    font-weight: 900;
    margin-right: 2px;
  }
}

@media screen and (max-width: $screen-sm) {
  .contract-table {
    .title {
      height: 93px;
      font-size: 18px;
      padding: 0 20px;
    }

    .credit-info {
      padding: 15px;
      div {
        float: none;
        width: 100%;
        &.odd {
          text-align: left;
        }
      }
    }

    .credit-summary {
      & td {
        padding: 15px;

        & span {
          display: block;
        }
      }
    }

    .terms-info {
      & td {
        padding: 15px;
      }
    }

    .text {
      & td {
        padding: 15px;
      }
    }
  }
}

@media screen and (max-width: $screen-xs) {
  .contract-table {
    .title {
      font-size: 16px;
    }

    .credit-summary {
      display: none;
    }

    .credit-summary--mobile {
      display: table-row;

      & table {
        border-collapse: collapse;
        border-spacing: 0;
        width: 100%
      }

      & td {
        line-height: 1.43;
        padding: 15px 30px;
        height: 0px;

        &.no-padding {
          padding: 0;
        }

        &.no-borders {
          border: none;
          width: 50%;

          &.left {
            border-left: solid 1px $darker-gray;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $screen-iphone-small) {
  .contract-table {
    .title {
      height: 83px;
      padding: 5px;
    }

    .credit-info {
      padding: 5px;
    }

    .credit-summary {
      & td {
        padding: 5px;
      }
    }

    .terms-info {
      & td {
        padding: 5px;
      }
    }

    .text {
      & td {
        padding: 5px;
      }
    }
  }
}

