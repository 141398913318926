.credit-summary .summary-info {
  float: left;
  margin-bottom: 10px;
  width: 50%;
  font-size: 16px;
}

.credit-summary .summary-info.lg {
  display: block;
}

.credit-summary .summary-info.sm {
  display: none;
}

.credit-summary .description {
  float: left;
  width: 50%;
}

.credit-summary .value {
  float: left;
  padding-right: 28px;
  width: 50%;
  color: #6b7c93;
  font-weight: 900;
}

@media screen and (max-width: 900px) {
  .credit-summary .summary-info {
    width: 100%;
  }
  .credit-summary .summary-info.lg {
    display: none;
  }
  .credit-summary .summary-info.sm {
    display: block;
  }
  .credit-summary .summary-info .description {
    padding-right: 28px;
    text-align: right;
  }
  .credit-summary .summary-info .value {
    padding-right: 0;
    text-align: left;
  }
}

@media screen and (max-width: 480px) {
  .credit-summary .summary-info .description {
    text-align: left;
  }
}
