@import "variables";
.modal-form {
  .title {
    font-size: 25px;
    font-weight: 300;
    color: $primary-color;
    text-align: center;
  }
  .input-container {
    margin: 10px auto;
    max-width: 300px;
  }

  .link {
    color: $primary-color;
    font-size: 18px;
    cursor: pointer;
    margin-top: 10px;
    text-align: center;
    text-decoration: underline;
  }
}