.step-form {
  margin: 0 auto;
  padding: 15px 0;
  min-height: 400px;
}

.step-form h1,
.step-form h2 {
  margin-top: 0;
  text-align: center;
  line-height: 1.1;
  color: #195eaa;
  font-weight: 300;
}

.step-form ul {
  padding: 0;
}

.step-form a {
  cursor: pointer;
  text-decoration: underline;
}

.step-form p {
  font-size: 16px;
}

.step-form .label-email {
  text-align: left;
  color: #6b7c93;
  font-size: 14px;
  font-weight: 900;
}

.step-form .error {
  text-align: center;
  color: #9f041b;
}

.step-form .btn-next {
  min-width: 170px;
}

.step-form .error-text {
  text-align: left;
  font-size: 14px;
}

.step-form .error-text ul {
  margin-top: 0;
  margin-left: 2em;
}

.step-form .error-text-clabe {
  position: absolute;
  margin-left: 34px;
}

.step-form .invalid {
  color: #9f041b;
}

.step-form .inputs-container {
  margin: 34px 0 0;
  text-align: center;
}

.step-form .input-container label {
  display: block;
  transition: top .4s ease, font-size .4s ease;
  min-height: 1em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
}

.step-form .block-options-container {
  margin: 40px 0 55px;
}

.step-form .block-option {
  display: inline-block;
  transition: border-color .2s ease, color .2s ease;
  margin: 15px;
  border: solid 2px transparent;
  border-radius: 2px;
  box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  cursor: pointer;
  padding: 10px 58px;
  width: 300px;
  height: 100px;
  text-align: center;
  color: #6b7c93;
  font-size: 18px;
  font-weight: 300;
}

.step-form .block-option:focus {
  border-radius: 2px;
  border-color: rgba(0, 179, 230, 0.5);
  box-shadow: 0 3px 16px 0 rgba(0, 179, 230, 0.1);
  color: rgba(0, 179, 230, 0.5);
  font-weight: bold;
}

.step-form .block-option.active, .step-form .block-option:hover {
  border-radius: 2px;
  border-color: #00b3e6;
  box-shadow: 0 3px 16px 0 rgba(0, 179, 230, 0.1);
  color: #00b3e6;
  font-weight: bold;
}

.step-form .block-option small {
  display: block;
  font-size: 14px;
}

.step-form .input-field {
  display: inline-block;
  float: none;
  text-align: left;
}

.step-form .container-btn-next {
  display: inline-block;
  margin-left: 10px;
  margin-top: 15px;
}

.step-form .forgot-password {
  margin: 5em 0 1em;
  text-align: center;
}

.step-form .mati-button-container {
  display: inherit;
  margin: 15% auto;
  width: 300px;
}

.step-form .mati-button-container button [type='submit'] {
  border: 0;
  background-color: transparent !important;
}

.step-form.step-01, .step-form.step-05,
.step-form.step-06 .step-form.front-id-picture {
  max-width: 780px;
}

.step-form.step-01 img, .step-form.step-05 img,
.step-form.step-06 .step-form.front-id-picture img {
  cursor: pointer;
}

.step-form.step-03 .block-option {
  padding: 10px 0;
}

.step-form.step-04 .info {
  margin: 0 auto;
  max-width: 780px;
}

.step-form.step-04 .table-summary-container {
  margin: 30px 0;
}

.step-form.step-04 .profeco-text {
  text-align: justify;
  font-size: 16px;
  font-weight: 900;
}

.step-form.step-04 .table-component {
  font-size: 12px;
}

.step-form.step-04 .table-component .description {
  float: left;
  width: 50%;
}

.step-form.step-04 .table-component .value {
  float: left;
  width: 50%;
  color: #6b7c93;
  font-weight: 900;
}

.step-form.step-06, .step-form.front-id-picture {
  min-height: 650px;
}

.step-form.step-06 .camera-input-container, .step-form.front-id-picture .camera-input-container {
  padding-top: 20px;
}

.step-form.step-06 h1, .step-form.front-id-picture h1 {
  max-width: 780px;
  margin: 0 auto;
}

.step-form.step-06 h2, .step-form.front-id-picture h2 {
  font-size: 26px;
}

.step-form.step-06 .id-selector, .step-form.front-id-picture .id-selector {
  cursor: pointer;
  color: #00b3e6;
  border: solid 2px #00b3e6;
  border-radius: 10px;
  font-weight: 900;
  width: 320px;
  margin: 0 auto 30px;
  text-align: left;
}

.step-form.step-06 .id-selector .active, .step-form.front-id-picture .id-selector .active {
  background-color: #00b3e6;
  color: #fff;
}

.step-form.step-06 .id-selector .option, .step-form.front-id-picture .id-selector .option {
  text-align: center;
  padding: 10px 0;
  display: inline-block;
  width: 50%;
}

.step-form .passport-dropdown {
  max-width: 220px;
  margin: 0 auto;
}

.step-form.step-07 {
  padding-top: 200px;
  max-width: 964px;
}

.step-form.step-07 h1 {
  line-height: 45px;
}

.step-form.step-07 h2 {
  line-height: 45px;
  color: inherit;
  font-size: 24px;
}

.step-form.step-07 .contract-summary {
  text-align: justify;
}

.step-form.step-07 .contract-summary span {
  font-weight: 800;
}

.step-form.step-07 .contract-summary ul {
  list-style: none;
}

.step-form.step-07 .contract-summary ul li {
  margin-bottom: 15px;
}

.step-form.step-07 .contract-summary ul li::before {
  display: inline-block;
  position: absolute;
  top: 8px;
  left: -15px;
  border-top: solid transparent;
  border-bottom: solid transparent;
  border-left: solid #00b3e6;
  border-width: 5px;
  width: 0;
  height: 0;
  content: ' ';
}

.step-form.step-07 .contract-summary .acceptance {
  text-align: center;
  color: #195eaa;
  font-size: 20px;
}

.step-form.step-08 {
  max-width: 1170px;
  text-align: center;
  color: #4a4a4a;
  font-family: Avenir;
  font-size: 16px;
}

.step-form.step-08 .contract-details {
  font-size: 16px;
  margin-top: 30px;
  text-align: justify;
}

.step-form.step-08 .contract-details b {
  font-weight: 900;
}

.step-form.step-08 .contract-details b.underline {
  text-decoration: underline;
}

.step-form.step-08 .contract-details .index {
  font-weight: 900;
  text-align: left;
}

.step-form.step-08 .contract-details .index span {
  display: block;
}

.step-form.step-08 .contract-details .clauses .client-label {
  margin-top: 30px;
  text-align: center;
}

.step-form.step-08 .contract-details .clauses .line {
  border: solid 1px #4a4a4a;
  height: 2px;
  margin: 113px auto 10px;
  width: 300px;
}

.step-form.step-08 .contract-details .clauses .loan-user-name {
  margin-bottom: 30px;
  text-align: center;
}

.step-form.step-08 .contract-details h4,
.step-form.step-08 .contract-details h3 {
  font-size: 16px;
  font-weight: 900;
  line-height: 1.5;
  text-align: center;
}

.step-form.step-08 .contract-details h3 {
  font-size: 20px;
  margin: 30px 0 24px;
}

.step-form.step-pagare {
  padding-top: 120px;
  text-align: justify;
  color: #4a4a4a;
}

.step-form.step-pagare .today {
  margin-bottom: 15px;
  text-align: right;
  font-size: 16px;
  font-weight: 900;
}

.step-form.step-pagare .title {
  margin-bottom: 40px;
  text-align: center;
  font-size: 22px;
  font-weight: 900;
}

.step-form.step-pagare .subtitle {
  margin-bottom: 40px;
  font-weight: 900;
}

.step-form.step-pagare .last-paragraph {
  margin-bottom: 50px;
}

.step-form.step-pagare .accept {
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 900;
}

.step-form.step-pagare .line {
  margin: 50px auto 10px;
  border: solid 1px #4a4a4a;
  width: 300px;
  height: 2px;
}

.step-form.step-pagare .loan-user-name {
  margin-bottom: 30px;
  text-align: center;
  font-size: 16px;
}

.step-form.step-pagare p {
  margin-block-end: 25px;
}

.step-form.step-pagare span {
  font-weight: 900;
}

.step-form.step-sign {
  max-width: 970px;
}

.step-form.step-sign h2 {
  margin: 120px auto 15px;
  max-width: 770px;
}

.step-form.step-sign .btn-container {
  margin-top: 40px;
  text-align: center;
}

.step-form.step-sign .sing-inside-square {
  font-size: 24px;
  text-align: center;
}

.step-form.step-08, .step-form.step-direct-debit {
  padding-top: 50px;
}

.step-form.step-direct-debit {
  color: #4a4a4a;
  font-family: Avenir;
  font-size: 16px;
  max-width: 1170px;
}

.step-form.step-direct-debit h3 {
  font-size: 22px;
  font-weight: 900;
  margin: 0 auto 20px;
  text-align: center;
  width: 380px;
}

.step-form.step-direct-debit h5 {
  font-size: 20px;
  font-weight: normal;
  line-height: 1.5;
  margin-top: 0;
  text-align: center;
}

.step-form.step-direct-debit p {
  line-height: 1.5;
  text-align: justify;
}

.step-form.step-direct-debit b {
  font-weight: 900;
}

.step-form.step-direct-debit ol {
  max-width: 970px;
  line-height: 2.5;
  padding-left: 85px;
  text-align: justify;
}

.step-form.step-direct-debit .date {
  text-align: right;
}

.step-form.step-direct-debit .signature {
  text-align: center;
  margin-top: 50px;
}

.step-form.step-direct-debit .signature p {
  text-align: center;
}

.step-form.step-direct-debit .signature .line {
  margin: 80px auto 1px;
  border: solid 1px #4a4a4a;
  width: 300px;
  height: 2px;
}

.step-form.step-10 {
  max-width: 970px;
  text-align: center;
}

.step-form.step-10 p {
  line-height: 28px;
  font-size: 20px;
}

.step-form.step-10 b,
.step-form.step-10 .mailto {
  font-weight: bold;
}

.step-form.step-10 img {
  margin-bottom: 25px;
}

@media screen and (max-width: 900px) {
  .step-form h1 {
    margin-bottom: 30px;
    font-size: 28px;
  }
  .step-form.step-01 :global(.col-4), .step-form.step-05 :global(.col-4) {
    width: 47%;
  }
  .step-form.step-04 .advice {
    display: none;
  }
  .step-form.step-04 .credit-summary .summary-info {
    width: 100%;
  }
  .step-form.step-04 .credit-summary .summary-info.lg {
    display: none;
  }
  .step-form.step-04 .credit-summary .summary-info.sm {
    display: block;
  }
  .step-form.step-04 .credit-summary .summary-info .description {
    padding-right: 28px;
    text-align: right;
  }
  .step-form.step-04 .credit-summary .summary-info .value {
    padding-right: 0;
    text-align: left;
  }
  .step-form.step-04 .table-summary-container {
    margin: 30px 0;
  }
  .step-form.step-04 .inputs-container {
    margin: 30px 0;
  }
  .step-form.step-04 .table-component {
    font-size: 14px;
  }
  .step-form.step-04 .table-component .description {
    padding-right: 10px;
    text-align: left;
  }
  .step-form.step-04 .table-component .value {
    text-align: right;
  }
  .step-form.step-07 {
    padding-top: 120px;
    max-width: 964px;
  }
  .step-form.step-07 h1 {
    line-height: inherit;
  }
  .step-form.step-07 h2 {
    line-height: inherit;
    font-size: inherit;
  }
  .step-form.step-07 .contract-summary ul li::before {
    top: 5px;
  }
  .step-form.step-07 .contract-summary .acceptance {
    text-align: center;
    color: #195eaa;
    font-size: 16px;
  }
  .step-form.front-id-picture h1 {
    max-width: 200px;
  }
  .step-form.front-id-picture h2 {
    font-size: 18px;
  }
  .step-form.step-step-sign h2 {
    position: absolute;
    top: 25px;
    margin: 0 auto;
    padding: 0 30%;
    width: 100%;
    font-size: 12px;
  }
  .step-form.step-step-sign .btn-container {
    margin-top: 15px;
    text-align: center;
  }
  .step-form.step-10 p {
    font-size: 18px;
  }
}

@media screen and (max-width: 480px) {
  .step-form {
    margin: 0 20px;
  }
  .step-form h1 {
    margin-bottom: 20px;
    font-size: 18px;
  }
  .step-form h2 {
    line-height: 1.67;
    font-size: 16px;
  }
  .step-form ul {
    padding: 0;
  }
  .step-form [class*='col-'] {
    width: 100% !important;
  }
  .step-form .error-text-clabe {
    margin-left: 0;
  }
  .step-form .btn-next {
    margin-top: 15px;
    min-width: 98%;
    height: 75px;
    font-size: 22px;
  }
  .step-form .inputs-container {
    margin: 30px 0;
  }
  .step-form .container-btn-next {
    display: block;
    margin-top: 100px;
    margin-left: 0;
  }
  .step-form .block-option {
    margin: 15px auto;
  }
  .step-form .col-12 {
    width: 100%;
  }
  .step-form.step-04 .credit-summary .summary-info .description {
    text-align: left;
  }
  .step-form.step-05 .bank {
    margin-top: 30px;
  }
  .step-form.step-08 {
    font-size: 12px;
    margin: 0;
  }
  .step-form.step-08 .contract-details {
    font-size: 12px;
  }
  .step-form.step-08 .contract-details p {
    font-size: 12px;
  }
  .step-form.step-08 .contract-details h4,
  .step-form.step-08 .contract-details h3 {
    font-size: 14px;
    margin: 20px 0 10px;
  }
  .step-form.step-08 .contract-details .clauses p {
    font-size: 11px;
  }
  .step-form.step-sign {
    margin: 0 18px;
  }
  .step-form.step-sign h2 {
    margin: inherit;
  }
  .step-form.step-sign .sing-inside-square {
    font-size: 14px;
  }
  .step-form.step-08, .step-form.step-direct-debit {
    padding-top: 120px;
  }
  .step-form.step-direct-debit {
    font-size: 14px;
  }
  .step-form.step-direct-debit h3 {
    font-size: 16px;
    font-weight: 900;
    margin: 10px auto 20px;
    width: 79%;
  }
  .step-form.step-direct-debit h5 {
    font-size: 16px;
  }
  .step-form.step-direct-debit ol {
    padding-left: 10px;
  }
  .step-form.step-direct-debit .date {
    text-align: center;
  }
  .step-form.step-direct-debit .signature {
    text-align: center;
    margin-top: 30px;
  }
  .step-form.step-direct-debit .signature p {
    text-align: center;
  }
  .step-form.step-direct-debit .signature .line {
    margin: 60px auto 1px;
    border: solid 1px #4a4a4a;
    width: 300px;
    height: 2px;
  }
  .step-form.step-06, .step-form.front-id-picture {
    min-height: 530px;
  }
}

@media screen and (max-width: 320px) {
  .step-form .block-option {
    padding: 10px 30px;
    width: 270px;
  }
  .step-form.step-08 {
    margin-left: -2px;
  }
  .step-form.step-sign {
    margin: 0 auto;
  }
  .step-form.step-sign h2 {
    padding: 0;
  }
}

@media screen and (min-width: 320px) {
  :global(.col-4-ns) .col-4-ns {
    width: 33.33333%;
  }
}

.contract-table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

.contract-table th,
.contract-table td {
  border: solid 1px #4a4a4a;
}

.contract-table .title {
  height: 60px;
  font-size: 22px;
  font-weight: 900;
}

.contract-table .credit-info {
  padding: 22px 30px;
}

.contract-table .credit-info div {
  float: left;
  text-align: left;
  width: 50%;
}

.contract-table .credit-info div.odd {
  text-align: right;
}

.contract-table .credit-summary,
.contract-table .credit-summary--mobile {
  display: table-row;
}

.contract-table .credit-summary td,
.contract-table .credit-summary--mobile td {
  line-height: 30px;
  padding: 15px 30px;
  height: 90px;
  width: 20%;
}

.contract-table .credit-summary td span,
.contract-table .credit-summary--mobile td span {
  display: block;
}

.contract-table .credit-summary--mobile {
  display: none;
}

.contract-table .terms-info td {
  padding: 15px 30px;
}

.contract-table .text td {
  padding: 25px 30px;
  text-align: justify;
}

.contract-table .line {
  border: solid 1px #4a4a4a;
  height: 2px;
  margin: 113px auto 10px;
  width: 300px;
}

.contract-table .loan-user-name {
  text-align: center;
}

.contract-table .label {
  font-weight: 900;
  margin-right: 2px;
}

@media screen and (max-width: 900px) {
  .contract-table .title {
    height: 93px;
    font-size: 18px;
    padding: 0 20px;
  }
  .contract-table .credit-info {
    padding: 15px;
  }
  .contract-table .credit-info div {
    float: none;
    width: 100%;
  }
  .contract-table .credit-info div.odd {
    text-align: left;
  }
  .contract-table .credit-summary td {
    padding: 15px;
  }
  .contract-table .credit-summary td span {
    display: block;
  }
  .contract-table .terms-info td {
    padding: 15px;
  }
  .contract-table .text td {
    padding: 15px;
  }
}

@media screen and (max-width: 480px) {
  .contract-table .title {
    font-size: 16px;
  }
  .contract-table .credit-summary {
    display: none;
  }
  .contract-table .credit-summary--mobile {
    display: table-row;
  }
  .contract-table .credit-summary--mobile table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
  }
  .contract-table .credit-summary--mobile td {
    line-height: 1.43;
    padding: 15px 30px;
    height: 0px;
  }
  .contract-table .credit-summary--mobile td.no-padding {
    padding: 0;
  }
  .contract-table .credit-summary--mobile td.no-borders {
    border: none;
    width: 50%;
  }
  .contract-table .credit-summary--mobile td.no-borders.left {
    border-left: solid 1px #4a4a4a;
  }
}

@media screen and (max-width: 320px) {
  .contract-table .title {
    height: 83px;
    padding: 5px;
  }
  .contract-table .credit-info {
    padding: 5px;
  }
  .contract-table .credit-summary td {
    padding: 5px;
  }
  .contract-table .terms-info td {
    padding: 5px;
  }
  .contract-table .text td {
    padding: 5px;
  }
}
