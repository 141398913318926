@import "variables";
.new-clabe {
  &.page-body {
    box-sizing: border-box;
  }

  :global(.btn-back) {
    position: absolute;
    top: 30px;
    left: 0;
    z-index: 1000;
    margin: 0;
    border: 0;
    font-size: 16px;
    font-weight: 300;

    &:active,
    &:hover {
      box-shadow: none;
    }
  }

  .back-text {
    display: inline-block;
  }

  .steps-chart {
    position: absolute;
    right: 0;
    width: 130px;
    height: 130px;
  }
}

@media screen and (max-width: $screen-sm) {
  .new-clabe {
    text-align: center;

    header {
      :global(.logo) {
        display: inline-block;
        margin: auto;
        width: 168px;
        text-align: center;

        img {
          vertical-align: middle;
        }
      }
    }

    [role='responsive-hidden'] {
      display: none;
    }

    .page-body {
      padding: 20px;
    }

    :global(.btn-back) {
      top: -55px;
      font-size: 16px;
    }

    .back-text {
      display: none;
    }

    .steps-chart {
      float: none;
      width: 110px;
      height: 110px;
    }
  }
}

@media screen and (max-width: $screen-xs) {
  .new-clabe {

    [role='responsive-hidden'] {
      display: none;
    }

    .page-body {
      padding-bottom: 0;
    }

    .step-form {
      padding-bottom: 0;
    }

    .btn-next {
      margin-bottom: 0;
    }
  }
}
