@import "variables";
.application-error {
  .title {
    color: $primary-color;
    font-size: 30px;
    margin-top: 30px;
  }

  .text {
    color: $base-gray;
    font-size: 18px;
    font-weight: 300;
    max-width: 900px;
    margin: 0 auto;

    & a {
      font-weight: bold;
    }

    & b {
      color: $secondary-color;
    }
  }
}
