.static-header {
  position: fixed;
  display: flex;
  justify-content: space-around;
  background-color: rgba(255, 255, 255, 0.6);
  width: 100%;
  z-index: 3;
}

.static-header .header-container {
  width: 85%;
}

.static-header .back {
  font-size: 16px;
  font-weight: 300;
}

.static-header .back:active, .static-header .back:hover {
  box-shadow: none;
}

.static-header .back .back-text {
  display: inline-block;
}

.static-header .step-tracking {
  position: absolute;
  right: 0;
  margin-right: 320px;
  letter-spacing: 4px;
  font-size: 12px;
}

.static-header .btn-action {
  padding-right: 12px;
  padding-left: 12px;
  width: 270px;
  font-size: 22px;
  height: 75px;
}

@media screen and (max-width: 900px) {
  .static-header {
    top: 0;
    left: 0;
  }
  .static-header :global(.logo) {
    top: 1px;
    width: 42px;
    overflow: hidden;
  }
  .static-header :global(.logo) img {
    width: auto !important;
    height: 60px;
  }
  .static-header .back {
    margin-bottom: 0;
  }
  .static-header .back-text {
    display: none;
  }
  .static-header .step-tracking {
    position: inherit;
    margin-left: 15px;
    margin-right: 20px;
  }
  .static-header .btn-action {
    margin-right: 15px;
    margin-bottom: 0;
    width: 170px;
    height: 60px;
    font-size: 16px;
  }
  .static-header .header-container {
    width: 100%;
  }
}
