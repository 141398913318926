@import '~susy/sass/susy';
$columns: 12;
$susy: (
  math: fluid,
  output: float,
  gutter-position: after,
  container: 1200px,
  columns: 12,
  gutters: 0.45,
  global-box-sizing: border-box,
);

$body-bg: #fff;
$white: #fff;
$black: #3a3a3a;

$primary-color: #195eaa;
$secondary-color: #00b3e6;
$blue: #1860ab;
$sapphire: #3023ae;
$lavender: #c86dd7;
$green: #07a135;
$green-aqua: #1de9aa;
$error-color-light: #f5515f;
$error-color: #9f041b;
$base-gray: #6b7c93;
$light-gray: #bebec1;
$disabled-gray: #98A4B3;
$darker-gray: #4a4a4a;

$ui-border: rgba($base-gray, 0.5);
$shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.1);

$font-family: 'Avenir', sans-serif;

$font-size: 20px;
$font-size-small: 16px;
$font-size-tiny: 14px;
$font-size-h1: 35px;
$font-size-h2: 30px;
$font-size-h1-mobile: 18px;
$font-size-h2-mobile: 16px;
$font-size-title-description: 20px;
$font-size-title-description-mobile: 16px;

$text-color: $base-gray;

$title-color: $primary-color;
$title-font-family: $font-family;
$title-font-weight: 300;

$button-font-size: 16px;
$button-font-size-mobile: 22px;
$button-border-radius: 10px;
$button-height: 50px;

$select-font-size: 13px;
$select-border-radius: 10px;
$select-height: 45px;
$select-width: 180px;

$screen-iphone-small: 320px;
$screen-xs: 480px;
$screen-sm: 900px;
$screen-lg: 1024px;
$screen-xl: 1280px;

$transition-speed: 0.2s;
