@keyframes spinner {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

button {
  cursor: pointer;
  font-family: "Avenir", sans-serif;
}

.btn {
  display: inline-block;
  transition: background-color .2s ease, color .2s ease, border .2s ease, box-shadow .2s ease;
  margin-bottom: 10px;
  outline: 0;
  border: solid 2px transparent;
  border-radius: 10px;
  background-color: #bebec1;
  background-image: none;
  cursor: pointer;
  padding: 13px 20px;
  min-height: 50px;
  vertical-align: middle;
  text-align: center;
  white-space: nowrap;
  color: #fff;
  font-family: "Avenir", sans-serif;
  font-size: 16px;
  font-weight: 900;
  touch-action: manipulation;
  box-sizing: border-box;
}

.btn:hover, .btn:focus, .btn.focus {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
  background-color: #cbcbcd;
  text-decoration: none;
}

.btn.block {
  width: 100%;
}

.btn[disabled] {
  opacity: .5;
  cursor: not-allowed;
}

.btn .spinner {
  display: inline-block;
  animation: spinner 2s infinite linear;
  opacity: .8;
  border: 4px solid #fff;
  border-radius: 50%;
  border-top-color: transparent;
  border-left-color: transparent;
  width: 20px;
  height: 20px;
}

.btn-primary {
  border-color: transparent;
  background-color: #00b3e6;
  color: #fff;
}

.btn-primary:hover, .btn-primary:focus, .btn-primary.focus {
  box-shadow: 0 3px 6px 0 rgba(0, 179, 230, 0.1);
  background-color: #01c7ff;
  text-decoration: none;
  color: #fff;
}

.btn-primary:active {
  box-shadow: 0 0 4px 0 #00b3e6;
}

.btn-primary .spinner {
  border-color: #fff;
  border-top-color: transparent;
  border-left-color: transparent;
}

.btn-primary-border {
  border-color: #00b3e6;
  background-color: transparent;
  color: #00b3e6;
}

.btn-primary-border:hover, .btn-primary-border:focus, .btn-primary-border.focus {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
  background-color: rgba(13, 13, 13, 0);
  text-decoration: none;
  color: #00b3e6;
}

.btn-primary-border:active {
  box-shadow: 0 0 4px 0 transparent;
}

.btn-primary-border .spinner {
  border-color: #00b3e6;
  border-top-color: transparent;
  border-left-color: transparent;
}

.btn-unstyled {
  border-color: transparent;
  background-color: transparent;
  color: #6b7c93;
  text-transform: none;
  font-size: 20px;
}

.btn-unstyled:hover, .btn-unstyled:focus, .btn-unstyled.focus {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
  background-color: rgba(13, 13, 13, 0);
  text-decoration: none;
  color: #6b7c93;
}

.btn-unstyled:active {
  box-shadow: 0 0 4px 0 transparent;
}

.btn-unstyled .spinner {
  border-color: #6b7c93;
  border-top-color: transparent;
  border-left-color: transparent;
}
