@import '../../node_modules/normalize-scss/sass/normalize/import-now';

* {
  position: relative;
  outline: 0;
  box-sizing: border-box;

  html {
    .clearfix {
      min-height: 1%;
    }
  }
}
