.reset-password {
  margin: 0 auto;
  padding: 15px 0;
  max-width: 780px;
  min-height: 400px;
}

.reset-password h1,
.reset-password h2 {
  margin-top: 0;
  text-align: center;
  line-height: 1.1;
  color: #195eaa;
  font-weight: 300;
}

.reset-password ul {
  padding: 0;
}

.reset-password .error-text {
  text-align: left;
  font-size: 14px;
}

.reset-password .error-text ul {
  margin-top: 0;
  margin-left: 2em;
}

.reset-password .invalid {
  color: #9f041b;
}

.reset-password .inputs-container {
  margin: 34px 0;
  text-align: center;
}

.reset-password .inputs-container .input-field {
  display: inline-block;
  float: none;
  text-align: left;
}

.reset-password .inputs-container .container-btn-next {
  display: inline-block;
  margin-left: 10px;
}

.reset-password .inputs-container .container-btn-next .btn-next {
  width: 210px;
}

@media screen and (max-width: 480px) {
  .reset-password {
    margin: 0 20px;
  }
  .reset-password h1 {
    margin-bottom: 20px;
    font-size: 18px;
  }
  .reset-password h2 {
    line-height: 1.67;
    font-size: 16px;
  }
  .reset-password .inputs-container {
    margin: 30px 0;
  }
  .reset-password .inputs-container .container-btn-next {
    display: block;
    margin-top: 100px;
    margin-left: 0;
  }
  .reset-password .inputs-container .container-btn-next .btn-next {
    margin-top: 15px;
    min-width: 98%;
    height: 75px;
    font-size: 22px;
  }
}
