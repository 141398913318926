@import "variables";
.forgot-password {
  margin: 0 auto;
  padding: 15px 0;
  max-width: 780px;
  min-height: 400px;

  h1,
  h2 {
    margin-top: 0;
    text-align: center;
    line-height: 1.1;
    color: $primary-color;
    font-weight: 300;
  }

  ul {
    padding: 0;
  }

  .success-message {
    margin-top: 62px;
  }

  .inputs-container {
    margin: 34px 0;
    text-align: center;

    .error-text {
      font-size: 14px;
    }

    .input-field {
      display: inline-block;
      float: none;
      text-align: left;
    }

    .container-btn-next {
      display: inline-block;
      margin-left: 10px;

      .btn-next {
        width: 210px;
      }
    }
  }
}

@media screen and (max-width: $screen-xs) {
  .forgot-password {
    margin: 0 20px;

    h1 {
      margin-bottom: 20px;
      font-size: $font-size-h1-mobile;
    }

    h2 {
      line-height: 1.67;
      font-size: $font-size-h2-mobile;
    }

    .inputs-container {
      margin: 30px 0;

      .container-btn-next {
        display: block;
        margin-left: 0;

        .btn-next {
          margin-top: 15px;
          min-width: 98%;
          height: 75px;
          font-size: $button-font-size-mobile;
        }
      }
    }
  }
}
