@import "variables";
.static-header {
  position: fixed;
  display: flex;
  justify-content: space-around;
  background-color: rgba($white, .6);
  width: 100%;
  z-index: 3;

  .header-container {
    width: 85%;
  }

  .back {
    font-size: 16px;
    font-weight: 300;

    &:active,
    &:hover {
      box-shadow: none;
    }

    .back-text {
      display: inline-block;
    }
  }

  .step-tracking {
    position: absolute;
    right: 0;
    margin-right: 320px;
    letter-spacing: 4px;
    font-size: 12px;
  }

  .btn-action {
    padding-right: 12px;
    padding-left: 12px;
    width: 270px;
    font-size: 22px;
    height: 75px;
  }
}

@media screen and (max-width: $screen-sm) {
  .static-header {
    top: 0;
    left: 0;

    :global(.logo) {
      top: 1px;
      width: 42px;
      overflow: hidden;

      img {
        width: auto !important;
        height: 60px;
      }
    }

    .back {
      margin-bottom: 0;
    }

    .back-text {
      display: none;
    }

    .step-tracking {
      position: inherit;
      margin-left: 15px;
      margin-right: 20px;
    }

    .btn-action {
      margin-right: 15px;
      margin-bottom: 0;
      width: 170px;
      height: 60px;
      font-size: 16px;
    }

    .header-container {
      width: 100%;
    }
  }
}
