@import "variables";
.circle-chart {
  overflow: visible !important;

  text {
    font-family: $font-family;
    font-size: 16px;
    fill: $base-gray;
  }

  .blue-text {
    font-size: 26px;
    font-weight: 900;
    fill: $primary-color;
  }

  .red-text {
    font-size: 26px;
    font-weight: 900;
    fill: $error-color;
  }

  .white-text {
    fill: $white;
  }

  .white-bold {
    font-size: 16px;
    font-weight: 900;
    fill: $white;
  }

  .saving {
    transition: all .2 ease;
  }


  :global(#blue) {
    .start {
      stop-color: $primary-color;
    }

    .end {
      stop-color: $secondary-color;
    }
  }

  :global(#red) {
    .start {
      stop-color: $error-color;
    }

    .end {
      stop-color: $error-color-light;
    }
  }

  :global(#purple) {
    .start {
      stop-color: #3023ae;
    }

    .end {
      stop-color: #c86dd7;
    }
  }

  :global(#green) {
    .start {
      stop-color: #07a135;
    }

    .end {
      stop-color: #1de9aa;
    }
  }

  :global(#green2) {
    .start {
      stop-color: #1ca442;
    }

    .end {
      stop-color: #2bd58e;
    }
  }
}

.tiny-circle-chart {
  .center-text {
    font-family: $font-family;
    font-size: 36px;
    font-weight: 300;
    fill: $secondary-color;
  }

  text {
    font-family: $font-family;
    font-size: 12px;
    fill: $base-gray;
  }
}
