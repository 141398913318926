@import "variables";
.scrollbar {
  :global(.vertical) {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    border-radius: 4px;
    width: 8px;
  }

  :global(.horizontal) {
    position: absolute;
    right: 3px;
    bottom: 0;
    left: 0;
    border-radius: 4px;
    height: 8px;
  }

  :global(.thumb) {
    border-radius: 4px;
    background-color: $light-gray;
  }
}
