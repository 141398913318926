@import "variables";
.page-container {
  padding-bottom: 100px;
  width: 100%;
  height: auto;
  min-height: 100%;

  header {
    padding: 30px 0;
    color: $base-gray;
    font-size: 18px;

    nav {
      float: right;
      width: 100%;
    }

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      text-align: right;
    }

    li {
      display: inline-block;
      padding: 10px 25px;

      &:last-child {
        padding: 0 25px;
      }
    }

    a {
      text-decoration: none;
      color: $base-gray;

      &:hover {
        text-decoration: underline;
      }
    }

    :global(.logo) {
      display: block;
      height: 58px;
    }

    .step-tracking {
      display: none;
    }

    .open-menu {
      display: none;
      position: absolute;
      right: 0;
      margin-right: 10px;
      cursor: pointer;
      padding: 5px;
      width: 45px;

      div {
        border-radius: 2px;
        background-color: $secondary-color;
        height: 3px;
      }

      .line-2 {
        margin: 10px 0;
        width: 70%;
      }

      .line-3 {
        width: 40%;
      }
    }

    .menu-mobile {
      position: fixed;
      top: -500px;
      left: 0;
      transition: top .8s ease, opacity .5s ease;
      opacity: 0;
      box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.1), 0 16px 12px 0 rgba(0, 0, 0, 0.5);
      background-color: $white;
      padding-top: 50px;
      width: 100%;
      color: $base-gray;
      font-size: 20px;
      z-index: 100000;

      &.open {
        top: -35px;
        opacity: 1;
      }

      .close-menu {
        position: absolute;
        top: 48px;
        right: 30px;
        z-index: 10;
        color: $secondary-color;
      }

      ul {
        padding: 20px 0;
      }

      li {
        display: block;
        text-align: center;

        &:last-child {
          padding: 0 25px;
        }
      }

      a {
        color: $base-gray;
      }

      button {
        color: $secondary-color;
      }
    }

    .logout {
      cursor: pointer;
    }

    .help-button {
      font-size: 18px;
      height: 60px;
      margin-top: 5px;
      width: 200px;
    }

    .referrer-button {
      font-size: 18px;
      height: 60px;
      margin-top: 5px;
      width: 200px;
      color: $white !important;
    }
    .disabled {
      pointer-events: none;
    }
  }

  footer {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba($white, .3);
    width: 100%;
    font-size: $font-size-small;
    padding: 0 30px;

    > div {
      border-top: 1px solid $ui-border;
      padding: 17px 0;

      * {
        display: inline-block;

        &:not(:first-child) {
          margin-left: 5px;
        }
      }
    }

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    li {
      display: inline-block;
      padding: 0 20px 10px 0;

      &:last-child {
        padding-right: 0;
      }
    }

    a {
      text-decoration: none;
      color: $base-gray;

      &:hover {
        text-decoration: underline;
      }
    }

    .menu {
      float: right;
      text-align: right;

      img {
        vertical-align: middle;
      }
    }

    .copyright {
      float: left;
      font-size: 14px;
    }
  }

  .digital-signature {
    &.page-body {
      box-sizing: border-box;
    }

    :global(.btn-back) {
      position: absolute;
      top: 30px;
      left: 0;
      z-index: 1000;
      margin: 0;
      border: 0;
      font-size: 16px;
      font-weight: 300;

      &:active,
      &:hover {
        box-shadow: none;
      }
    }

    .back-text {
      display: inline-block;
    }

    .steps-chart {
      position: absolute;
      right: 0;
      width: 130px;
      height: 130px;
    }
  }
}

@media screen and (max-width: $screen-sm) {
  .page-container {
    padding-bottom: 0;

    header {
      padding: 27px 0 0;
      font-size: $font-size-small;

      > div {
        display: flex;
        justify-content: center;
      }

      :global(.logo) {
        display: inline-block;
        height: auto;

        img {
          width: 131px;
          margin-top: 8px;
        }
      }

      .step-tracking {
        display: inline-block;
        position: absolute;
        right: 0;
        margin-right: 2em;
        letter-spacing: 4px;
        font-size: 12px;
      }

      .menu {
        display: none;
      }

      .open-menu {
        display: block;
      }
    }

    footer {
      text-align: center;
      margin-top: 50px;
      position: relative;

      & span {
        display: block;
      }

      > div {
        padding: 1em 0 0.5em;

        * {
          display: block;

          &:not(:first-child) {
            margin-left: 0;
          }
        }
      }
    }

    .digital-signature {
      text-align: center;

      header {
        :global(.logo) {
          display: inline-block;
          margin: auto;
          width: 168px;
          text-align: center;

          img {
            vertical-align: middle;
          }
        }
      }

      [role='responsive-hidden'] {
        display: none;
      }

      .page-body {
        padding: 20px;
      }

      :global(.btn-back) {
        top: -55px;
        font-size: 16px;
      }

      .back-text {
        display: none;
      }

      .steps-chart {
        float: none;
        width: 110px;
        height: 110px;
      }
    }
  }
}

@media screen and (max-width: $screen-xs) {
  .digital-signature {

    [role='responsive-hidden'] {
      display: none;
    }

    .page-body {
      padding-bottom: 0;
    }

    .step-form {
      padding-bottom: 0;
    }

    .btn-next {
      margin-bottom: 0;
    }
  }
}
