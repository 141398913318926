.modal .close {
  position: absolute;
  top: 20px;
  right: 20px;
  margin: 5px 13px 0;
  border: 0;
  background-color: transparent;
  padding: 0;
  z-index: 1000000;
}

.modal .modal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: .5;
  z-index: 10;
  background-color: #3a3a3a;
}

.modal .modal-view {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 11;
  padding-bottom: 100px;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.modal .over-modal {
  margin: 30px auto;
  min-height: 1px;
  color: #fff;
}

.modal .modal-container {
  margin: auto;
  margin-bottom: 30px;
  border-radius: 10px;
  box-shadow: 0 22px 24px 0 0 6px 16px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  width: 650px;
}

.modal .modal-header {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  font-size: 1.07em;
  padding: 20px;
}

.modal .modal-header * {
  color: #3a3a3a;
}

.modal .modal-header > div {
  display: table-cell;
  vertical-align: middle;
}

.modal .modal-header h1 {
  margin: 0;
  font-size: 1em;
  min-height: 0;
  padding: 0;
}

.modal .modal-body {
  padding: 20px;
  font-size: .8em;
}

.modal .modal-footer {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 20px;
  padding-top: 0;
  text-align: center;
}

.modal .modal-footer .btn {
  margin-bottom: 0;
  width: 50%;
  font-size: .8em;
  min-height: 40px;
  width: 190px;
  padding: 0;
}

.modal .modal-enter {
  opacity: 0;
}

.modal .modal-enter .modal-container {
  transform: scale(0.5);
}

.modal .modal-enter .modal-enter-active {
  transition: opacity 0.2s ease;
  opacity: 1;
}

.modal .modal-enter .modal-enter-active .modal-container {
  transform: scale(1);
  transition: transform 0.2s ease-out;
}

.modal .modal-exit {
  opacity: 1;
}

.modal .modal-exit .modal-container {
  transform: scale(1);
}

.modal .modal-exit .modal-exit-active {
  transition: opacity 0.2s ease;
  opacity: 0;
}

.modal .modal-exit .modal-exit-active .modal-container {
  transform: scale(0.5);
  transition: transform 0.2s ease-out;
}

.modal .wizard .wizard-steps {
  position: absolute;
  bottom: -42px;
  z-index: 2;
  width: 100%;
  text-align: center;
}

.modal .wizard .wizard-step {
  display: inline-block;
  transition: border-color .3s ease, background-color .4s ease;
  z-index: 2;
  margin: 0 25px;
  border: solid 2px #bebec1;
  border-radius: 50%;
  background-color: #fff;
  width: 45px;
  height: 45px;
  vertical-align: middle;
  line-height: 45px;
  font-size: 20px;
}

.modal .wizard .wizard-step span {
  transition: opacity .2s ease;
  opacity: 1;
  color: #6b7c93;
}

.modal .wizard .wizard-step::before {
  display: inline-block;
  position: absolute;
  top: 12px;
  left: 10px;
  transform: scale(0);
  transition: opacity .4s ease, transform .4s ease;
  opacity: 0;
  background-repeat: no-repeat;
  background-size: contain;
  width: 20px;
  height: 20px;
  content: '';
}

.modal .wizard .wizard-step.active, .modal .wizard .wizard-step.done {
  border-color: #195eaa;
  background-color: #195eaa;
}

.modal .wizard .wizard-step.active span, .modal .wizard .wizard-step.done span {
  color: #fff;
}

.modal .wizard .wizard-step.active {
  transform: scale(1);
  animation: step-animation .4s;
}

.modal .wizard .wizard-step.done span {
  opacity: 0;
}

.modal .wizard .wizard-step.done::before {
  transform: scale(1);
  opacity: 1;
}

.no-scroll {
  height: 100%;
  overflow: hidden;
}

@keyframes step-animation {
  0% {
    transform: scale(0.9);
  }
  75% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

@media screen and (max-width: 900px) {
  .modal .modal-container {
    width: 500px;
  }
  .modal .modal-body {
    text-align: left;
  }
}

@media screen and (max-width: 480px) {
  .modal .modal-container {
    width: 310px;
  }
  .modal .modal-body {
    text-align: left;
  }
}
