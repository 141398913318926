/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Correct the line height in all browsers.
     * 2. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  line-height: 1.15;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
}

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0;
}

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block;
}

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block;
}

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px;
}

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */
}

/**
     * Add the correct display in IE.
     */
main {
  display: block;
}

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */
}

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Chrome 57- and Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */
}

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit;
}

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder;
}

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic;
}

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000;
}

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%;
}

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block;
}

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0;
}

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none;
}

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden;
}

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */
}

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible;
}

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none;
}

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible;
}

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */
}

[type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}

/**
     * Correct the padding in Firefox.
     */
fieldset {
  padding: 0.35em 0.75em 0.625em;
}

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */
}

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */
}

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto;
}

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block;
}

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item;
}

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block;
}

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block;
}

/**
     * Add the correct display in IE.
     */
template {
  display: none;
}

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none;
}

* {
  position: relative;
  outline: 0;
  box-sizing: border-box;
}

* html .clearfix {
  min-height: 1%;
}

@font-face {
  font-family: 'Avenir';
  font-weight: 300;
  font-style: normal;
  src: url("../fonts/Avenir-Light.woff") format("woff"), url("../fonts/Avenir-Light.ttf") format("truetype"), url("../fonts/Avenir-Light.svg") format("svg");
}

@font-face {
  font-family: 'Avenir';
  font-weight: normal;
  font-style: normal;
  src: url("../fonts/Avenir-Book.woff") format("woff"), url("../fonts/Avenir-Book.ttf") format("truetype"), url("../fonts/Avenir-Book.svg") format("svg");
}

@font-face {
  font-family: 'Avenir';
  font-weight: 700;
  font-style: normal;
  src: url("../fonts/Avenir-Black.woff") format("woff"), url("../fonts/Avenir-Black.ttf") format("truetype"), url("../fonts/Avenir-Black.svg") format("svg");
}

@font-face {
  font-family: 'Avenir';
  font-weight: 900;
  font-style: normal;
  src: url("../fonts/Avenir-Heavy.woff") format("woff"), url("../fonts/Avenir-Heavy.ttf") format("truetype"), url("../fonts/Avenir-Heavy.svg") format("svg");
}

:global(.clearfix) {
  display: block;
  min-height: 1%;
}

:global(.clearfix)::after {
  display: block;
  clear: both;
  visibility: hidden;
  height: 0;
  font-size: 0;
  content: ' ';
}

:global(.container) {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

:global(.container):after {
  content: " ";
  display: block;
  clear: both;
}

:global(.row) {
  clear: both;
  display: block;
  width: 100%;
  min-height: 1%;
}

:global(.row)::after {
  display: block;
  clear: both;
  visibility: hidden;
  height: 0;
  font-size: 0;
  content: ' ';
}

:global(.col-1) {
  width: 8.33333%;
  padding-right: 20px;
  float: left;
  min-height: 1px;
}

:global(.col-2) {
  width: 16.66667%;
  padding-right: 20px;
  float: left;
  min-height: 1px;
}

:global(.col-3) {
  width: 25%;
  padding-right: 20px;
  float: left;
  min-height: 1px;
}

:global(.col-4) {
  width: 33.33333%;
  padding-right: 20px;
  float: left;
  min-height: 1px;
}

:global(.col-5) {
  width: 41.66667%;
  padding-right: 20px;
  float: left;
  min-height: 1px;
}

:global(.col-6) {
  width: 50%;
  padding-right: 20px;
  float: left;
  min-height: 1px;
}

:global(.col-7) {
  width: 58.33333%;
  padding-right: 20px;
  float: left;
  min-height: 1px;
}

:global(.col-8) {
  width: 66.66667%;
  padding-right: 20px;
  float: left;
  min-height: 1px;
}

:global(.col-9) {
  width: 75%;
  padding-right: 20px;
  float: left;
  min-height: 1px;
}

:global(.col-10) {
  width: 83.33333%;
  padding-right: 20px;
  float: left;
  min-height: 1px;
}

:global(.col-11) {
  width: 91.66667%;
  padding-right: 20px;
  float: left;
  min-height: 1px;
}

:global(.col-12) {
  width: 100%;
  padding-right: 20px;
  float: left;
  min-height: 1px;
}

[class*='col-']:last-child {
  margin-right: 0;
}

.offset-1 {
  margin-left: 5.89971%;
}

.offset-2 {
  margin-left: 14.45428%;
}

.offset-3 {
  margin-left: 23.00885%;
}

.offset-4 {
  margin-left: 31.56342%;
}

.offset-5 {
  margin-left: 40.11799%;
}

.offset-6 {
  margin-left: 48.67257%;
}

.offset-7 {
  margin-left: 57.22714%;
}

.offset-8 {
  margin-left: 65.78171%;
}

.offset-9 {
  margin-left: 74.33628%;
}

.offset-10 {
  margin-left: 82.89086%;
}

.offset-11 {
  margin-left: 91.44543%;
}

.offset-12 {
  margin-left: 100%;
}

:global(.no-gutter) {
  padding: 0;
}

@media screen and (max-width: 1280px) {
  :global(.container) {
    max-width: 96%;
  }
  :global(.container) :global(.container) {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
  }
  :global(.container) :global(.container):after {
    content: " ";
    display: block;
    clear: both;
  }
}

@media screen and (max-width: 480px) {
  [class*='col-'] {
    margin-right: 0;
    width: 100%;
  }
}

:global html {
  height: 100%;
  box-sizing: border-box;
}

:global body {
  position: relative;
  margin: 0;
  background-image: url("../images/background.png");
  background-position: center -120px;
  min-height: 100%;
  line-height: 1.5;
  color: #6b7c93;
  font-family: "Avenir", sans-serif;
  font-size: 20px;
  font-weight: 300;
}

:global a {
  color: #00b3e6;
}

:global h1 {
  font-size: 35px;
}

:global h2 {
  font-size: 30px;
}

:global #app {
  position: absolute;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 900px) {
  :global body {
    font-size: 18px;
  }
}

@media screen and (max-width: 480px) {
  :global body {
    background-image: url("../images/mobile-bg.png");
    background-position: center -360px;
    font-size: 16px;
  }
}

:global .flex {
  display: flex;
}

:global .flex.space-between-items {
  justify-content: space-between;
}

:global .flex.justify-items {
  justify-content: center;
}

:global .flex.place-right {
  justify-content: flex-end;
}

:global .flex.center-items {
  align-items: center;
}

:global .flex.wrap-items {
  flex-wrap: wrap;
}

:global .flex.auto-items > * {
  flex: 1 1 auto;
}

:global .flex.pad-items > * {
  flex-basis: 0;
  flex-grow: 1;
  margin-bottom: 25px;
  margin-left: 30px;
}

:global .flex.pad-items > *:first-child {
  margin-left: 0;
}

:global .flex .auto {
  flex: 1 1 auto;
}

:global .flex .block {
  flex: none;
}

:global .link {
  cursor: pointer;
  text-decoration: underline;
}

:global .link a {
  color: #195eaa;
}

:global .title-description {
  margin: 0 auto;
  max-width: 800px;
  text-align: center;
  font-size: 20px;
}

:global .strong {
  font-weight: 800;
}

:global .align-center {
  text-align: center;
}

@media screen and (max-width: 480px) {
  :global .title-description {
    font-size: 16px;
  }
}
