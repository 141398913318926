.step-form {
  margin: 0 auto;
  padding: 15px 0;
  min-height: 400px;
}

.step-form.step-10 {
  max-width: 970px;
  text-align: center;
}

.step-form.step-10 p {
  line-height: 28px;
  font-size: 20px;
}

.step-form.step-10 b,
.step-form.step-10 .mailto {
  font-weight: bold;
}

.step-form.step-10 img {
  margin-bottom: 25px;
}

@media screen and (max-width: 900px) {
  .step-form.step-10 p {
    font-size: 18px;
  }
}
