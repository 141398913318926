.page-container {
  padding-bottom: 100px;
  width: 100%;
  height: auto;
  min-height: 100%;
}

.page-container header {
  padding: 30px 0;
  color: #6b7c93;
  font-size: 18px;
}

.page-container header nav {
  float: right;
  width: 100%;
}

.page-container header ul {
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: right;
}

.page-container header li {
  display: inline-block;
  padding: 10px 25px;
}

.page-container header li:last-child {
  padding: 0 25px;
}

.page-container header a {
  text-decoration: none;
  color: #6b7c93;
}

.page-container header a:hover {
  text-decoration: underline;
}

.page-container header :global(.logo) {
  display: block;
  height: 58px;
}

.page-container header .step-tracking {
  display: none;
}

.page-container header .open-menu {
  display: none;
  position: absolute;
  right: 0;
  margin-right: 10px;
  cursor: pointer;
  padding: 5px;
  width: 45px;
}

.page-container header .open-menu div {
  border-radius: 2px;
  background-color: #00b3e6;
  height: 3px;
}

.page-container header .open-menu .line-2 {
  margin: 10px 0;
  width: 70%;
}

.page-container header .open-menu .line-3 {
  width: 40%;
}

.page-container header .menu-mobile {
  position: fixed;
  top: -500px;
  left: 0;
  transition: top .8s ease, opacity .5s ease;
  opacity: 0;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.1), 0 16px 12px 0 rgba(0, 0, 0, 0.5);
  background-color: #fff;
  padding-top: 50px;
  width: 100%;
  color: #6b7c93;
  font-size: 20px;
  z-index: 100000;
}

.page-container header .menu-mobile.open {
  top: -35px;
  opacity: 1;
}

.page-container header .menu-mobile .close-menu {
  position: absolute;
  top: 48px;
  right: 30px;
  z-index: 10;
  color: #00b3e6;
}

.page-container header .menu-mobile ul {
  padding: 20px 0;
}

.page-container header .menu-mobile li {
  display: block;
  text-align: center;
}

.page-container header .menu-mobile li:last-child {
  padding: 0 25px;
}

.page-container header .menu-mobile a {
  color: #6b7c93;
}

.page-container header .menu-mobile button {
  color: #00b3e6;
}

.page-container header .logout {
  cursor: pointer;
}

.page-container header .help-button {
  font-size: 18px;
  height: 60px;
  margin-top: 5px;
  width: 200px;
}

.page-container header .referrer-button {
  font-size: 18px;
  height: 60px;
  margin-top: 5px;
  width: 200px;
  color: #fff !important;
}

.page-container header .disabled {
  pointer-events: none;
}

.page-container footer {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.3);
  width: 100%;
  font-size: 16px;
  padding: 0 30px;
}

.page-container footer > div {
  border-top: 1px solid rgba(107, 124, 147, 0.5);
  padding: 17px 0;
}

.page-container footer > div * {
  display: inline-block;
}

.page-container footer > div *:not(:first-child) {
  margin-left: 5px;
}

.page-container footer ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.page-container footer li {
  display: inline-block;
  padding: 0 20px 10px 0;
}

.page-container footer li:last-child {
  padding-right: 0;
}

.page-container footer a {
  text-decoration: none;
  color: #6b7c93;
}

.page-container footer a:hover {
  text-decoration: underline;
}

.page-container footer .menu {
  float: right;
  text-align: right;
}

.page-container footer .menu img {
  vertical-align: middle;
}

.page-container footer .copyright {
  float: left;
  font-size: 14px;
}

.page-container .digital-signature.page-body {
  box-sizing: border-box;
}

.page-container .digital-signature :global(.btn-back) {
  position: absolute;
  top: 30px;
  left: 0;
  z-index: 1000;
  margin: 0;
  border: 0;
  font-size: 16px;
  font-weight: 300;
}

.page-container .digital-signature :global(.btn-back):active, .page-container .digital-signature :global(.btn-back):hover {
  box-shadow: none;
}

.page-container .digital-signature .back-text {
  display: inline-block;
}

.page-container .digital-signature .steps-chart {
  position: absolute;
  right: 0;
  width: 130px;
  height: 130px;
}

@media screen and (max-width: 900px) {
  .page-container {
    padding-bottom: 0;
  }
  .page-container header {
    padding: 27px 0 0;
    font-size: 16px;
  }
  .page-container header > div {
    display: flex;
    justify-content: center;
  }
  .page-container header :global(.logo) {
    display: inline-block;
    height: auto;
  }
  .page-container header :global(.logo) img {
    width: 131px;
    margin-top: 8px;
  }
  .page-container header .step-tracking {
    display: inline-block;
    position: absolute;
    right: 0;
    margin-right: 2em;
    letter-spacing: 4px;
    font-size: 12px;
  }
  .page-container header .menu {
    display: none;
  }
  .page-container header .open-menu {
    display: block;
  }
  .page-container footer {
    text-align: center;
    margin-top: 50px;
    position: relative;
  }
  .page-container footer span {
    display: block;
  }
  .page-container footer > div {
    padding: 1em 0 0.5em;
  }
  .page-container footer > div * {
    display: block;
  }
  .page-container footer > div *:not(:first-child) {
    margin-left: 0;
  }
  .page-container .digital-signature {
    text-align: center;
  }
  .page-container .digital-signature header :global(.logo) {
    display: inline-block;
    margin: auto;
    width: 168px;
    text-align: center;
  }
  .page-container .digital-signature header :global(.logo) img {
    vertical-align: middle;
  }
  .page-container .digital-signature [role='responsive-hidden'] {
    display: none;
  }
  .page-container .digital-signature .page-body {
    padding: 20px;
  }
  .page-container .digital-signature :global(.btn-back) {
    top: -55px;
    font-size: 16px;
  }
  .page-container .digital-signature .back-text {
    display: none;
  }
  .page-container .digital-signature .steps-chart {
    float: none;
    width: 110px;
    height: 110px;
  }
}

@media screen and (max-width: 480px) {
  .digital-signature [role='responsive-hidden'] {
    display: none;
  }
  .digital-signature .page-body {
    padding-bottom: 0;
  }
  .digital-signature .step-form {
    padding-bottom: 0;
  }
  .digital-signature .btn-next {
    margin-bottom: 0;
  }
}
