@import "variables";
:global(.camera-component),
.camera-mobile-component {
  text-align: center;
  width: 570px;
  height: 330px;
  margin: 20px auto;
  box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.18);
  background-color: #474747;
  border-radius: 17px;
  position: relative;
  
  .video,
  .error{
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: block;
    border-radius: 17px;
    box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.18);
    background-color: #474747;
    border-radius: 17px;
  }

  & video {
    -moz-transform: scale(-1, 1);
    -webkit-transform: scale(-1, 1); -o-transform: scale(-1, 1);
    transform: scale(-1, 1); filter: FlipH;
  }
  
  button {
    margin-top: 40px;
    width: 217px;
  }

  canvas {
    margin: auto;
  }
  
  .error {
    box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.18);
    background-color: #474747;
    border-radius: 17px;
    font-size: 16px;

    & span {
      top: 48%;
      position: relative;
      color: white;
    }
  }
  
  .block {
    display: block;
  }
  
  .none {
    display: none;
  }
  
  .head-shape {
    position: absolute;
    left: calc(50% - 100px);
    width: 200px;
    top: 10%;
  }
  
  .card-frame {
    position: absolute;
    width: 450px;
    left: calc(50% - 225px);
    top: 25px;
  }
  
  .no-visible {
    visibility: hidden;
  }

  .card-frame-container {
    position: absolute;
    top: 0;
    width: 570px;
    height: 330px;
    box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.18);
    background-color: #474747;
    border-radius: 17px;

    &.transparent {
      background-color: transparent;
    }

    .document {
      position: absolute;
      width: 400px;
      left: calc(50% - 200px);
      top: 38px;

      &.passport {
        top: 30px;
      }
    }

    .card-frame {
      position: absolute;
    }
  }

  .ids-container {
    min-height: 300px;
  }

  .reload {
    position: absolute;
    left: 20px;
    bottom: 20px;
    cursor: pointer;
  }
}

.camera-mobile-component {
  margin: 0 auto;
  width: 100%;
  height: 206px;
  box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.18);
  background-color: #474747;
  border-radius: 17px;
  position: relative;

  &.head {
    height: 330px;
    box-shadow: none;
    background-color: transparent;
  }

  .head-shape {
    left: calc(50% - 83px);
    width: 165px;
    top: 50px;
  }
  
  .card-frame {
    position: absolute;
    width: 300px;
    top: 10px;
    left: 27px;
    z-index: 4;
  }
  
  .document-container {
    height: 100%;
  }
  
  .document,
  .passport {
    position: absolute;
    width: 260px;
    top: 21px;
    left: 47px;
  }
  
  .passport {
    top: 12px;
  }
}
