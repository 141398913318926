@import "variables";
@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.waiting-modal {
  padding-top: 25px;

  .loader {
    left: calc(50% - 60px);
    border: 11px solid  $blue;
    border-top-color: transparent;
    border-left-color: transparent;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    animation: spin 1.5s linear infinite;
    z-index: 100001;
  }

  .title {
    font-size: 25px;
    font-weight: 300;
    color: $primary-color;
    text-align: center;
    padding: 45px 0 25px;
  }

  .error {
    .error-text {
      padding: 25px 0 25px;
      font-size: 25px;
      font-weight: 300;
      color: $primary-color;
      text-align: center;
    }

    .error-subtitle-text {
      font-size: 18px;
      padding-bottom: 35px;
    }
  }
}

@media screen and (max-width: $screen-sm) {
  .waiting-modal {
    .loader {
      left: calc(50% - 30px);
      border: 7px solid $base-gray;
      border-top: 7px solid $blue;
      width: 40px;
      height: 40px;
    }
  }
}
