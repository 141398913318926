html {
  height: 100%;
  box-sizing: border-box;
}

body {
  position: relative;
  margin: 0;
  background-image: url('../images/background.png');
  background-position: center -120px;
  min-height: 100%;
  line-height: 1.5;
  color: $base-gray;
  font-family: $font-family;
  font-size: 20px;
  font-weight: 300;
}

a {
  color: $secondary-color;
}

h1 {
  font-size: $font-size-h1;
}

h2 {
  font-size: $font-size-h2;
}

#app {
  position: absolute;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: $screen-sm) {
  body {
    font-size: 18px;
  }
}

@media screen and (max-width: $screen-xs) {
  body {
    background-image: url('../images/mobile-bg.png');
    background-position: center -360px;
    font-size: 16px;
  }
}
