@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spinner-container {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 100000;
}

.spinner-container.table {
  display: inline-table;
  position: relative;
}

.spinner-container .spinner {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #fff;
  opacity: 0.5;
}

.spinner-container .loader {
  position: fixed;
  top: calc(50% - 60px);
  left: calc(50% - 60px);
  border: 16px solid #1860ab;
  border-top-color: transparent;
  border-left-color: transparent;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  z-index: 100001;
}

@media screen and (max-width: 900px) {
  .spinner-container .loader {
    top: calc(50% - 30px);
    left: calc(50% - 30px);
    border: 8px solid #6b7c93;
    border-top: 8px solid #1860ab;
    width: 60px;
    height: 60px;
  }
}
