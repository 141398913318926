@import "variables";
.overlay-message {
  width: 100%;
  height: 100%;
  opacity: 0.88;
  background-color: #eeeeee;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1001;

  .content {
    img {
      display: block;
      margin: 0 auto 20px;
    }
    .message {
      color: $primary-color;
      font-size: 35px;
      font-weight: 300;
      text-align: center;
      margin: 10% auto 0;
      max-width: 500px;
    }

    button {
      display: block;
      font-size: 22px;
      margin: 20px auto 0;
      width: 330px;
      height: 75px;
    }
  }
}

@media screen and (max-width: $screen-sm) {
  .overlay-message {
    .content {
      .message {
        margin: 20% auto 0;
        max-width: 300px;
        font-size: 22px;
      }

      button {
        font-size: 22px;
      }
    }
  }
}
