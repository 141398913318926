@font-face {
  font-family: 'Avenir';
  font-weight: 300;
  font-style: normal;
  src: url('../fonts/Avenir-Light.woff') format('woff'), url('../fonts/Avenir-Light.ttf') format('truetype'),
  url('../fonts/Avenir-Light.svg') format('svg');
}

@font-face {
  font-family: 'Avenir';
  font-weight: normal;
  font-style: normal;
  src: url('../fonts/Avenir-Book.woff') format('woff'), url('../fonts/Avenir-Book.ttf') format('truetype'),
  url('../fonts/Avenir-Book.svg') format('svg');
}

@font-face {
  font-family: 'Avenir';
  font-weight: 700;
  font-style: normal;
  src: url('../fonts/Avenir-Black.woff') format('woff'), url('../fonts/Avenir-Black.ttf') format('truetype'),
  url('../fonts/Avenir-Black.svg') format('svg');
}

@font-face {
  font-family: 'Avenir';
  font-weight: 900;
  font-style: normal;
  src: url('../fonts/Avenir-Heavy.woff') format('woff'), url('../fonts/Avenir-Heavy.ttf') format('truetype'),
  url('../fonts/Avenir-Heavy.svg') format('svg');
}
