@import "variables";
@keyframes input-spinner {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.input-container {
  display: block;
  font-size: 16px;

  label {
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: top .4s ease, font-size .4s ease;
    z-index: 1;
    min-height: 1em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    pointer-events: none;
  }

  input,
  textarea {
    display: block;
    background-color: $white;
  }

  &.inline {
    display: inline-block;
  }

  &.disabled {
    color: $disabled-gray;
    pointer-events: none;

    & input[type=text],
    & input[type=numbers] {
      color: $disabled-gray;
      border-bottom-color: $disabled-gray;
    }
  }

  .input-error-text {
    position: absolute;
    top: 90%;
    width: 100%;
    color: $error-color;
  }

  &.error {
    &:not(.disabled) {
      text-align: left;
      height:

      label {
        color: $error-color;
      }

      .input {
        border-color: $error-color;
        text-align: left;
      }
    }
  }
}

.focus {
  padding-top: 14px;

  label {
    top: 12px;
    font-size: 14px;
    font-weight: 900;
  }
}

.input-group {
  display: block;
  margin-bottom: 1em;
  width: 100%;
  border-collapse: separate;

  .input-addon {
    position: absolute;
    top: 0;
    z-index: 1;
    padding: 13px 0;
    text-align: center;

    i {
      top: -3px;
      font-size: 25px;
    }

    &:first-child {
      left: 0;
    }

    &:last-child {
      right: 0;
    }
  }

  .spinner {
    display: inline-block;
    animation: input-spinner .8s infinite linear;
    opacity: .8;
    border-radius: 50%;
    border-top-color: transparent;
    border-left-color: transparent;
    width: 20px;
    height: 20px;
  }
}

.input {
  transition: border-color .2s ease;
  border: solid 0 $base-gray;
  border-bottom-width: 2px;
  border-radius: 0;
  padding: 13px 0;
  width: 100%;
  color: $base-gray;
  font-size: 16px;
  font-weight: 300;

  &:focus,
  &:active,
  &.active {
    border-color: lighten($primary-color, 10%);
  }

  &.right-icon {
    padding-right: 10px;
  }

  &.left-icon {
    padding-left: 10px;
  }

  &[type='number'] {
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      margin: 0;
      appearance: none;
    }
  }
}

::input-placeholder {
  color: lighten($base-gray, 30%);
  font-size: .8em;
}

::placeholder {
  color: lighten($base-gray, 30%);
  font-size: .8em;
}

@media screen and (max-width: $screen-xs) {
  .input-container {
    .input-error-text {
      position: relative;
      top: inherit;
    }
  }
}
