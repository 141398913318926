.dropdown-container {
  display: block;
  width: 100%;
  white-space: nowrap;
}

.dropdown-container > label {
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: top .4s ease, font-size .4s ease;
  z-index: 1;
  pointer-events: none;
}

.dropdown-container .arrow {
  position: absolute;
  right: 0;
}

.dropdown-container.touched {
  padding-top: 14px;
}

.dropdown-container.touched label {
  top: .6em;
  font-size: 14px;
  font-weight: 900;
}

.dropdown-container.disabled label {
  color: #98A4B3;
}

.dropdown-container.disabled .dropdown {
  border-bottom-color: #98A4B3;
}

.dropdown-container.disabled .dropdown button {
  cursor: not-allowed;
  color: #98A4B3;
  border-bottom: #98A4B3;
}

.dropdown-container .dropdown {
  display: block;
  margin-bottom: 21px;
  border-bottom: solid 2px #6b7c93;
  border-radius: 0;
  width: 100%;
  border-collapse: separate;
  line-height: 0;
}

.dropdown-container .dropdown button {
  transition: color .2s ease, border .2s ease;
  outline: 0;
  border: 0;
  border-radius: 10px;
  background-color: #fff;
  background-image: none;
  cursor: pointer;
  padding: 0;
  width: 100%;
  height: 45px;
  text-align: left;
  letter-spacing: .8px;
  color: #6b7c93;
  touch-action: manipulation;
  box-sizing: border-box;
}

.dropdown-container .dropdown button:hover, .dropdown-container .dropdown button:focus, .dropdown-container .dropdown button.focus {
  text-decoration: none;
  color: #6b7c93;
}

.dropdown-container .dropdown[disabled] {
  opacity: .6;
  background-color: #bebec1;
}

.dropdown-container .dropdown[disabled] button {
  cursor: not-allowed;
}

.dropdown-container .dropdown i {
  float: right;
  margin-right: 20px;
}

.dropdown-container .dropdown .dropdown-label {
  display: block;
  padding-right: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: normal;
}

.dropdown-container .dropdown .dropdown-options {
  display: none;
  position: absolute;
  z-index: 3;
  margin: 0 0 20px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: #fff;
  width: 100%;
  font-size: 16px;
}

.dropdown-container .dropdown .dropdown-options ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.dropdown-container .dropdown .dropdown-options li:global(.active) {
  background-color: #195eaa;
}

.dropdown-container .dropdown .dropdown-options li:global(.active) a {
  color: #fff;
}

.dropdown-container .dropdown .dropdown-options li:focus:not(.disabled), .dropdown-container .dropdown .dropdown-options li:hover:not(.disabled) {
  background-color: rgba(25, 94, 170, 0.85);
}

.dropdown-container .dropdown .dropdown-options li:focus:not(.disabled) a, .dropdown-container .dropdown .dropdown-options li:hover:not(.disabled) a {
  color: #fff;
}

.dropdown-container .dropdown .dropdown-options li:last-child a {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.dropdown-container .dropdown .dropdown-options li a {
  display: block;
  transition: none;
  cursor: pointer;
  padding: 15px 20px;
  overflow: hidden;
  text-decoration: none;
  text-overflow: ellipsis;
  line-height: 1em;
  color: #6b7c93;
  font-weight: normal;
}

.dropdown-container .dropdown .dropdown-options li a[disabled] {
  opacity: .6;
  background-color: #bebec1;
  cursor: not-allowed;
}

.dropdown-container .dropdown.open button {
  z-index: 2;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.3);
  background-color: #fff;
  width: 200px;
  color: #195eaa;
}

.dropdown-container .dropdown.open button::after {
  display: block;
  position: absolute;
  bottom: -1px;
  z-index: 3;
  background-color: #fff;
  width: 100%;
  height: 8px;
  content: '';
}

.dropdown-container .dropdown.open .arrow {
  display: none;
}

.dropdown-container .dropdown.open .dropdown-label {
  padding: 0 16px 0 20px;
}

.dropdown-container .dropdown.open .dropdown-options {
  display: block;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.3);
  width: 200px;
}

.dropdown-container .dropdown.open .dropdown-options::before {
  display: block;
  position: absolute;
  top: -10px;
  background-color: #fff;
  width: 100%;
  height: 10px;
  content: '';
}

.dropdown-container .dropdown.open.options-on-top button {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.dropdown-container .dropdown.open.options-on-top .dropdown-options {
  bottom: 25px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.dropdown-container .dropdown.open.options-on-top .dropdown-options li:last-child a {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.dropdown-container .dropdown.open.options-on-top .dropdown-options li:first-child a {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.dropdown-container .input-error-text {
  position: relative;
  top: -14px;
  color: #9f041b;
  font-size: 12px;
}

.dropdown-container .error .input {
  border-color: #9f041b;
}

@media screen and (max-width: 480px) {
  .dropdown-container .dropdown.open button {
    width: 100%;
  }
  .dropdown-container .dropdown.open .dropdown-options {
    width: 100%;
  }
}
