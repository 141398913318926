@keyframes input-spinner {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.input-container {
  display: block;
  font-size: 16px;
}

.input-container label {
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: top .4s ease, font-size .4s ease;
  z-index: 1;
  min-height: 1em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
}

.input-container input,
.input-container textarea {
  display: block;
  background-color: #fff;
}

.input-container.inline {
  display: inline-block;
}

.input-container.disabled {
  color: #98A4B3;
  pointer-events: none;
}

.input-container.disabled input[type=text],
.input-container.disabled input[type=numbers] {
  color: #98A4B3;
  border-bottom-color: #98A4B3;
}

.input-container .input-error-text {
  position: absolute;
  top: 90%;
  width: 100%;
  color: #9f041b;
}

.input-container.error:not(.disabled) {
  text-align: left;
  height: label;
  height-color: #9f041b;
}

.input-container.error:not(.disabled) .input {
  border-color: #9f041b;
  text-align: left;
}

.focus {
  padding-top: 14px;
}

.focus label {
  top: 12px;
  font-size: 14px;
  font-weight: 900;
}

.input-group {
  display: block;
  margin-bottom: 1em;
  width: 100%;
  border-collapse: separate;
}

.input-group .input-addon {
  position: absolute;
  top: 0;
  z-index: 1;
  padding: 13px 0;
  text-align: center;
}

.input-group .input-addon i {
  top: -3px;
  font-size: 25px;
}

.input-group .input-addon:first-child {
  left: 0;
}

.input-group .input-addon:last-child {
  right: 0;
}

.input-group .spinner {
  display: inline-block;
  animation: input-spinner .8s infinite linear;
  opacity: .8;
  border-radius: 50%;
  border-top-color: transparent;
  border-left-color: transparent;
  width: 20px;
  height: 20px;
}

.input {
  transition: border-color .2s ease;
  border: solid 0 #6b7c93;
  border-bottom-width: 2px;
  border-radius: 0;
  padding: 13px 0;
  width: 100%;
  color: #6b7c93;
  font-size: 16px;
  font-weight: 300;
}

.input:focus, .input:active, .input.active {
  border-color: #2077d6;
}

.input.right-icon {
  padding-right: 10px;
}

.input.left-icon {
  padding-left: 10px;
}

.input[type='number']::-webkit-outer-spin-button, .input[type='number']::-webkit-inner-spin-button {
  margin: 0;
  appearance: none;
}

::input-placeholder {
  color: #c3cad4;
  font-size: .8em;
}

::placeholder {
  color: #c3cad4;
  font-size: .8em;
}

@media screen and (max-width: 480px) {
  .input-container .input-error-text {
    position: relative;
    top: inherit;
  }
}
