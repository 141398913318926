@import "variables";
$btn-shadow: 0 3px 6px 0 rgba(0, 0, 0, .1);
@mixin button-variant($btn-color, $color, $border-color: transparent) {
  border-color: $border-color;
  background-color: $btn-color;
  color: $color;
  &:hover,
  &:focus,
  &.focus {
    box-shadow: 0 3px 6px 0 rgba($btn-color, .1);
    background-color: lighten($btn-color, 5%);
    text-decoration: none;
    color: $color;
  }
  &:active {
    box-shadow: 0 0 4px 0 $btn-color;
  }
  .spinner {
    border-color: $color;
    border-top-color: transparent;
    border-left-color: transparent;
  }
}
@keyframes spinner {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
button {
  cursor: pointer;
  font-family: $font-family;
}
.btn {
  display: inline-block;
  transition: background-color .2s ease, color .2s ease, border .2s ease, box-shadow .2s ease;
  margin-bottom: 10px;
  outline: 0;
  border: solid 2px transparent;
  border-radius: $button-border-radius;
  background-color: $light-gray;
  background-image: none;
  cursor: pointer;
  padding: 13px 20px;
  min-height: $button-height;
  vertical-align: middle;
  text-align: center;
  white-space: nowrap;
  color: $white;
  font-family: $font-family;
  font-size: $button-font-size;
  font-weight: 900;
  touch-action: manipulation;
  box-sizing: border-box;
  &:hover,
  &:focus,
  &.focus {
    box-shadow: $btn-shadow;
    background-color: lighten($light-gray, 5%);
    text-decoration: none;
  }
  &.block {
    width: 100%;
  }
  &[disabled] {
    opacity: .5;
    cursor: not-allowed;
  }
  .spinner {
    display: inline-block;
    animation: spinner 2s infinite linear;
    opacity: .8;
    border: 4px solid $white;
    border-radius: 50%;
    border-top-color: transparent;
    border-left-color: transparent;
    width: 20px;
    height: 20px;
  }
}
.btn-primary {
  @include button-variant($secondary-color, $white);
}
.btn-primary-border {
  @include button-variant(transparent, $secondary-color, $secondary-color);
}
.btn-unstyled {
  @include button-variant(transparent, $text-color);
  text-transform: none;
  font-size: $font-size;
}
