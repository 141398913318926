@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.waiting-modal {
  padding-top: 25px;
}

.waiting-modal .loader {
  left: calc(50% - 60px);
  border: 11px solid #1860ab;
  border-top-color: transparent;
  border-left-color: transparent;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 1.5s linear infinite;
  z-index: 100001;
}

.waiting-modal .title {
  font-size: 25px;
  font-weight: 300;
  color: #195eaa;
  text-align: center;
  padding: 45px 0 25px;
}

.waiting-modal .error .error-text {
  padding: 25px 0 25px;
  font-size: 25px;
  font-weight: 300;
  color: #195eaa;
  text-align: center;
}

.waiting-modal .error .error-subtitle-text {
  font-size: 18px;
  padding-bottom: 35px;
}

@media screen and (max-width: 900px) {
  .waiting-modal .loader {
    left: calc(50% - 30px);
    border: 7px solid #6b7c93;
    border-top: 7px solid #1860ab;
    width: 40px;
    height: 40px;
  }
}
