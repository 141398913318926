@import "variables";
.table-info {
	font-size: 20px;
	text-align: center;
  vertical-align: middle;
  width: 100%;
	.table-body {
		.table-row {
			background-color: #f4f5f7;
			&.odd {
        background-color: #FFFF;
        &.table-cell {
          &.isSelected {
            border: solid 2px #f4f5f7;
          }
        }
			}
		}
	}
	.table-row {
    &.expanded {
      .table-cell {
        border-top: none;
      }
    }
		.table-cell {
      color: $base-gray;
      position: relative;
      padding: 18px;
      border: 2px solid #FFFF;
      border-bottom: 4px solid #FFFF;
			&.isExpandible {
        cursor: pointer;
      }
      &.expanded {
        border-bottom: none;
      }
		}
  }
  .table-header {
		background-color: #195eaa;
		font-weight: 900;
		.table-cell {
      color: #FFFF;
      position: relative;
      padding: 18px;
      border: 2px solid #FFFF;
		}
	}
	.chevron{
		position: absolute;
    right: 15px;
		border: solid $base-gray;
		border-width: 0 3px 3px 0;
		display: inline-block;
		padding: 6px;
		vertical-align: middle;
		transform: rotate(45deg);
    -webkit-transform: rotate(45deg);

      &.up {
        transform: rotate(225deg);
        -webkit-transform: rotate(225deg);
        top: 25px;
      }
   }
}

@media screen and (max-width: $screen-sm) { 
  .table-info {
    font-size: 14px;
    .table-body {
      font-size: 13px;
    }
    .table-header {
      .table-cell {
        padding: 15px;
      }
    }
    .chevron{
      right: 15px;
      top: 20px;
      padding: 3px;
    }
  }
}

@media screen and (max-width: $screen-xs) {
  .table-info {
    .table-header {
      .table-cell {
        padding: 10px;
      }
    }
    .chevron{
      padding: 2px;
      border-width: 0 2px 2px 0;
      right: 8px;
      top: 22px;
    }
  }
}
