@import "variables";
.select {
  display: inline-block;
  transition: background-color .2s ease, color .2s ease, border .2s ease, box-shadow .2s ease;
  margin-bottom: 10px;
  outline: 0;
  border: solid 1px $base-gray;
  border-radius: $select-border-radius;
  background-image: none;
  padding: 0 20px;
  min-width: $select-width;
  height: $select-height;
  vertical-align: middle;
  text-align: left;
  white-space: nowrap;
  color: $darker-gray;

  &:hover,
  &:focus,
  &.focus {
    text-decoration: none;
    color: $text-color;
  }

  &.block {
    width: 100%;
  }

  &.disabled {
    opacity: .6;
    cursor: not-allowed;
    pointer-events: none;

    select {
      cursor: not-allowed;
    }
  }

  .select-container {
    label {
      display: block;
      text-transform: uppercase;
    }
  }

  select {
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0;
    z-index: 3;
    cursor: pointer;
    width: 100%;
    height: 100%;
    appearance: menulist-button;
  }

  i {
    position: absolute;
    top: 14px;
    right: 20px;
  }

  .custom-select {
    display: block;
    z-index: 0;
    margin: 0 25px 0 0;
    height: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 3;
    white-space: nowrap;
  }

  &.open {
    $box-shadow: 0 0 16px 0 rgba(0, 0, 0, .1);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow: $box-shadow;
    background-color: $white;
    color: $primary-color;
  }
}
