.signature-pad .signature-wrapper {
  border: 2px solid #00b3e6;
  border-radius: 10px;
  width: 308px;
  height: 230px;
  margin: 0 auto;
  background-color: #fff;
}

.signature-pad .reload {
  cursor: pointer;
  position: absolute;
  left: 15px;
  bottom: 5px;
}

.signature-pad .reload img {
  display: inline;
}

@media screen and (max-width: 900px) {
  .signature-pad .reload {
    left: 14px;
    bottom: 16px;
  }
  .signature-pad .reload span {
    display: none;
  }
}
