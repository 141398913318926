@import "variables";
.signature-pad {
  .signature-wrapper {
    border: 2px solid $secondary-color;
    border-radius: 10px;
    width: 308px;
    height: 230px;
    margin: 0 auto;
    background-color: #fff;
  }

  .reload {
    cursor: pointer;
    position: absolute;
    left: 15px;
    bottom: 5px;
    & img {
      display: inline;
    }
  }
}

@media screen and (max-width: $screen-sm) {
  .signature-pad {
    .reload {
      left: 14px;
      bottom: 16px;

      & span {
        display: none;
      }
    }
  }
}

@media screen and (max-width: $screen-sm) {
}
