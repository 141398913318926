.link {
  cursor: pointer;
  text-decoration: underline;

  a {
    color: $primary-color;
  }
}

.title-description {
  margin: 0 auto;
  max-width: 800px;
  text-align: center;
  font-size: $font-size-title-description;
}

.strong {
  font-weight: 800;
}

.align-center {
  text-align: center;
}

@media screen and (max-width: $screen-xs) {
  .title-description {
    font-size: $font-size-title-description-mobile;
  }
}
