@import "variables";
.tooltip {
  $arrow-size: 16px;

  position: absolute;
  z-index: 2;
  border-radius: 5px;
  background-color: $darker-gray;
  width: 210px;
  color: $white;
  pointer-events: none;

  &::before {
    display: block;
    position: absolute;
    border: solid 8px transparent;
    width: 0;
    height: 0;
    content: '';
  }

  .tooltip-inner {
    display: inline-block;
    padding: 11px;
    text-align: left;
    text-transform: none;
    line-height: 1.4;
    font-size: 12px;
  }

  &.top {
    bottom: calc(100% + #{$arrow-size / 1.3});
    left: 50%;
    transform: translateX(-50%);

    &::before {
      bottom: -$arrow-size;
      left: 50%;
      transform: translateX(-50%);
      border-top-color: $darker-gray;
    }
  }

  &.top-right {
    right: -30px;
    bottom: calc(100% + #{$arrow-size / 1.3});

    &::before {
      right: 30px;
      bottom: -$arrow-size;
      border-top-color: $darker-gray;
    }
  }

  &.bottom {
    top: calc(100% + #{$arrow-size / 1.3});
    left: 50%;
    transform: translateX(-50%);

    &::before {
      top: -$arrow-size;
      left: 50%;
      transform: translateX(-50%);
      border-bottom-color: $darker-gray;
    }
  }

  &.left {
    top: 50%;
    right: calc(100% + #{$arrow-size / 1.3});
    transform: translateY(-50%);

    &::before {
      top: 50%;
      right: -$arrow-size;
      transform: translateY(-50%);
      border-left-color: $darker-gray;
    }
  }

  &.right {
    top: 50%;
    left: calc(100% + #{$arrow-size / 1.3});
    transform: translateY(-50%);

    &::before {
      top: 50%;
      left: -$arrow-size;
      transform: translateY(-50%);
      border-right-color: $darker-gray;
    }
  }

}
