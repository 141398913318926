@import "variables";
.alert-message {
  margin-bottom: 1em;
  border-radius: 10px;
  padding: 19px 15px;
  width: 100%;
  text-align: left;
  color: $white;

  a {
    text-decoration: underline;
    color: $white;
  }

  &.primary {
    background-color: $primary-color;
  }

  &.error {
    background-color: $error-color;
  }
}
