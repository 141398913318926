@import "variables";
.contract-table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;

  & th,
  & td {
    border: solid 1px $darker-gray;
  }

  .title {
    height: 60px;
    font-size: 22px;
    font-weight: 900;
  }

  .credit-info {
    padding: 22px 30px;
    div {
      float: left;
      text-align: left;
      width: 50%;

      &.odd {
        text-align: right;
      }
    }
  }

  .credit-summary,
  .credit-summary--mobile {
    display: table-row;
    & td {
      line-height: 30px;
      padding: 15px 30px;
      height: 90px;
      width: 20%;

      & span {
        display: block;
      }
    }
  }

  .credit-summary--mobile {
    display: none;
  }

  .terms-info {
    & td {
      padding: 15px 30px;
    }
  }

  .text {
    & td {
      padding: 25px 30px;
      text-align: justify;
    }
  }

  .line {
    border: solid 1px $darker-gray;
    height: 2px;
    margin: 113px auto 10px;
    width: 300px;
  }

  .loan-user-name {
    text-align: center;
  }

  .label {
    font-weight: 900;
    margin-right: 2px;
  }
}

@media screen and (max-width: $screen-sm) {
  .contract-table {
    .title {
      height: 93px;
      font-size: 18px;
      padding: 0 20px;
    }

    .credit-info {
      padding: 15px;
      div {
        float: none;
        width: 100%;
        &.odd {
          text-align: left;
        }
      }
    }

    .credit-summary {
      & td {
        padding: 15px;

        & span {
          display: block;
        }
      }
    }

    .terms-info {
      & td {
        padding: 15px;
      }
    }

    .text {
      & td {
        padding: 15px;
      }
    }
  }
}

@media screen and (max-width: $screen-xs) {
  .contract-table {
    .title {
      font-size: 16px;
    }

    .credit-summary {
      display: none;
    }

    .credit-summary--mobile {
      display: table-row;

      & table {
        border-collapse: collapse;
        border-spacing: 0;
        width: 100%
      }

      & td {
        line-height: 1.43;
        padding: 15px 30px;
        height: 0px;

        &.no-padding {
          padding: 0;
        }

        &.no-borders {
          border: none;
          width: 50%;

          &.left {
            border-left: solid 1px $darker-gray;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $screen-iphone-small) {
  .contract-table {
    .title {
      height: 83px;
      padding: 5px;
    }

    .credit-info {
      padding: 5px;
    }

    .credit-summary {
      & td {
        padding: 5px;
      }
    }

    .terms-info {
      & td {
        padding: 5px;
      }
    }

    .text {
      & td {
        padding: 5px;
      }
    }
  }
}
