.digital-signature.page-body {
  box-sizing: border-box;
}

.digital-signature :global(.btn-back) {
  position: absolute;
  top: 30px;
  left: 0;
  z-index: 1000;
  margin: 0;
  border: 0;
  font-size: 16px;
  font-weight: 300;
}

.digital-signature :global(.btn-back):active, .digital-signature :global(.btn-back):hover {
  box-shadow: none;
}

.digital-signature .back-text {
  display: inline-block;
}

.digital-signature .steps-chart {
  position: absolute;
  right: 0;
  width: 130px;
  height: 130px;
}

@media screen and (max-width: 900px) {
  .digital-signature {
    text-align: center;
  }
  .digital-signature header :global(.logo) {
    display: inline-block;
    margin: auto;
    width: 168px;
    text-align: center;
  }
  .digital-signature header :global(.logo) img {
    vertical-align: middle;
  }
  .digital-signature [role='responsive-hidden'] {
    display: none;
  }
  .digital-signature .page-body {
    padding: 20px;
  }
  .digital-signature :global(.btn-back) {
    top: -55px;
    font-size: 16px;
  }
  .digital-signature .back-text {
    display: none;
  }
  .digital-signature .steps-chart {
    float: none;
    width: 110px;
    height: 110px;
  }
}

@media screen and (max-width: 480px) {
  .digital-signature [role='responsive-hidden'] {
    display: none;
  }
  .digital-signature .page-body {
    padding-bottom: 0;
  }
  .digital-signature .step-form {
    padding-bottom: 0;
  }
  .digital-signature .btn-next {
    margin-bottom: 0;
  }
}
