.table-info {
  font-size: 20px;
  text-align: center;
  vertical-align: middle;
  width: 100%;
}

.table-info .table-body .table-row {
  background-color: #f4f5f7;
}

.table-info .table-body .table-row.odd {
  background-color: #FFFF;
}

.table-info .table-body .table-row.odd.table-cell.isSelected {
  border: solid 2px #f4f5f7;
}

.table-info .table-row.expanded .table-cell {
  border-top: none;
}

.table-info .table-row .table-cell {
  color: #6b7c93;
  position: relative;
  padding: 18px;
  border: 2px solid #FFFF;
  border-bottom: 4px solid #FFFF;
}

.table-info .table-row .table-cell.isExpandible {
  cursor: pointer;
}

.table-info .table-row .table-cell.expanded {
  border-bottom: none;
}

.table-info .table-header {
  background-color: #195eaa;
  font-weight: 900;
}

.table-info .table-header .table-cell {
  color: #FFFF;
  position: relative;
  padding: 18px;
  border: 2px solid #FFFF;
}

.table-info .chevron {
  position: absolute;
  right: 15px;
  border: solid #6b7c93;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 6px;
  vertical-align: middle;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.table-info .chevron.up {
  transform: rotate(225deg);
  -webkit-transform: rotate(225deg);
  top: 25px;
}

@media screen and (max-width: 900px) {
  .table-info {
    font-size: 14px;
  }
  .table-info .table-body {
    font-size: 13px;
  }
  .table-info .table-header .table-cell {
    padding: 15px;
  }
  .table-info .chevron {
    right: 15px;
    top: 20px;
    padding: 3px;
  }
}

@media screen and (max-width: 480px) {
  .table-info .table-header .table-cell {
    padding: 10px;
  }
  .table-info .chevron {
    padding: 2px;
    border-width: 0 2px 2px 0;
    right: 8px;
    top: 22px;
  }
}
