@import "variables";
.error-message {
  margin-bottom: 2em;
  text-align: center;
  color: $error-color;

  ul {
    list-style: none;
  }

  a {
    text-decoration: underline;
  }
}
