@import "variables";
$shadow: rgba(0, 0, 0, .09) !default;
$transition-speed: .2s !default;

.modal {
  .close {
    position: absolute;
    top: 20px;
    right: 20px;
    margin: 5px 13px 0;
    border: 0;
    background-color: transparent;
    padding: 0;
    z-index: 1000000;
  }

  .modal-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: .5;
    z-index: 10;
    background-color: $black;
  }

  .modal-view {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 11;
    padding-bottom: 100px;
    width: 100%;
    height: 100%;
    overflow: auto;
  }

  .over-modal {
    margin: 30px auto;
    min-height: 1px;
    color: $white;
  }

  .modal-container {
    margin: auto;
    margin-bottom: 30px;
    border-radius: 10px;
    box-shadow: 0 22px 24px 0 $shadow;
    background-color: $white;
    width: 650px;
  }

  .modal-header {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: $white;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    font-size: 1.07em;
    padding: 20px;

    * {
      color: $black;
    }

    > div {
      display: table-cell;
      vertical-align: middle;
    }

    h1 {
      margin: 0;
      font-size: 1em;
      min-height: 0;
      padding: 0;
    }
  }

  .modal-body {
    padding: 20px;
    font-size: .8em;
  }

  .modal-footer {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 20px;
    padding-top: 0;
    text-align: center;

    .btn {
      margin-bottom: 0;
      width: 50%;
      font-size: .8em;
      min-height: 40px;
      width: 190px;
      padding: 0;
    }
  }

  // React Animation
  .modal-enter {
    opacity: 0;

    .modal-container {
      transform: scale(.5);
    }

    .modal-enter-active {
      transition: opacity $transition-speed ease;
      opacity: 1;

      .modal-container {
        transform: scale(1);
        transition: transform $transition-speed ease-out;
      }
    }
  }

  .modal-exit {
    opacity: 1;

    .modal-container {
      transform: scale(1);
    }

    .modal-exit-active {
      transition: opacity $transition-speed ease;
      opacity: 0;

      .modal-container {
        transform: scale(.5);
        transition: transform $transition-speed ease-out;
      }
    }
  }

  .wizard {
    .wizard-steps {
      position: absolute;
      bottom: -42px;
      z-index: 2;
      width: 100%;
      text-align: center;
    }

    .wizard-step {
      display: inline-block;
      transition: border-color .3s ease, background-color .4s ease;
      z-index: 2;
      margin: 0 25px;
      border: solid 2px $light-gray;
      border-radius: 50%;
      background-color: $white;
      width: 45px;
      height: 45px;
      vertical-align: middle;
      line-height: 45px;
      font-size: 20px;

      span {
        transition: opacity .2s ease;
        opacity: 1;
        color: $base-gray;
      }

      &::before {
        display: inline-block;
        position: absolute;
        top: 12px;
        left: 10px;
        transform: scale(0);
        transition: opacity .4s ease, transform .4s ease;
        opacity: 0;
        background-repeat: no-repeat;
        background-size: contain;
        width: 20px;
        height: 20px;
        content: '';
      }

      &.active,
      &.done {
        border-color: $primary-color;
        background-color: $primary-color;

        span {
          color: $white;
        }
      }

      &.active {
        transform: scale(1);
        animation: step-animation .4s;
      }

      &.done {
        span {
          opacity: 0;
        }

        &::before {
          transform: scale(1);
          opacity: 1;
        }
      }
    }
  }
}

.no-scroll {
  height: 100%;
  overflow: hidden;
}

@keyframes step-animation {
  0% {
    transform: scale(.9);
  }

  75% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

@media screen and (max-width: $screen-sm) {
  .modal {
    .modal-container {
      width: 500px;
    }

    .modal-body {
      text-align: left;
    }
  }
}

@media screen and (max-width: $screen-xs) {
  .modal {
    .modal-container {
      width: 310px;
    }

    .modal-body {
      text-align: left;
    }
  }
}
