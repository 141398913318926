@import "variables";
$shadow: rgba(0, 0, 0, .3);

.dropdown-container {
  display: block;
  width: 100%;
  white-space: nowrap;

  > label {
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: top .4s ease, font-size .4s ease;
    z-index: 1;
    pointer-events: none;
  }

  .arrow {
    position: absolute;
    right: 0;
  }

  &.touched {
    padding-top: 14px;

    label {
      top: .6em;
      font-size: 14px;
      font-weight: 900;
    }
  }

  &.disabled {
    label {
      color: $disabled-gray;
    }
    .dropdown {
      border-bottom-color: $disabled-gray;
      button {
        cursor: not-allowed;
        color: $disabled-gray;
        border-bottom: $disabled-gray;
      }
    }
  }

  .dropdown {
    $hover-color: rgba($primary-color, .85);

    display: block;
    margin-bottom: 21px;
    border-bottom: solid 2px $base-gray;
    border-radius: 0;
    width: 100%;
    border-collapse: separate;
    line-height: 0;

    button {
      transition: color .2s ease, border .2s ease;
      outline: 0;
      border: 0;
      border-radius: 10px;
      background-color: $white;
      background-image: none;
      cursor: pointer;
      padding: 0;
      width: 100%;
      height: $select-height;
      text-align: left;
      letter-spacing: .8px;
      color: $text-color;
      touch-action: manipulation;
      box-sizing: border-box;

      &:hover,
      &:focus,
      &.focus {
        text-decoration: none;
        color: $text-color;
      }
    }

    &[disabled] {
      opacity: .6;
      background-color: $light-gray;

      button {
        cursor: not-allowed;
      }
    }

    i {
      float: right;
      margin-right: 20px;
    }

    .dropdown-label {
      display: block;
      padding-right: 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: normal;
    }

    .dropdown-options {
      display: none;
      position: absolute;
      z-index: 3;
      margin: 0 0 20px;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      background-color: $white;
      width: 100%;
      font-size: $font-size-small;

      ul {
        margin: 0;
        padding: 0;
        list-style: none;
      }

      li {
         &:global(.active) {
            background-color: $primary-color;
            a {
               color: $white;
            }
         }
         
         &:focus,
         &:hover {
            &:not(.disabled) {
               background-color: $hover-color;
               
               a {
                  color: $white;
               }
            }
         }


        &:last-child {
          a {
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
          }
        }

        a {
          display: block;
          transition: none;
          cursor: pointer;
          padding: 15px 20px;
          overflow: hidden;
          text-decoration: none;
          text-overflow: ellipsis;
          line-height: 1em;
          color: $text-color;
          font-weight: normal;

          &[disabled] {
            opacity: .6;
            background-color: $light-gray;
            cursor: not-allowed;
          }
        }
      }
    }

    &.open {
      button {
        z-index: 2;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        box-shadow: 0 4px 16px 0 $shadow;
        background-color: $white;
        width: 200px;
        color: $primary-color;

        &::after {
          display: block;
          position: absolute;
          bottom: -1px;
          z-index: 3;
          background-color: $white;
          width: 100%;
          height: 8px;
          content: '';
        }
      }

      .arrow {
        display: none;
      }

      .dropdown-label {
        padding: 0 16px 0 20px;
      }

      .dropdown-options {
        display: block;
        box-shadow: 0 4px 16px 0 $shadow;
        width: 200px;

        &::before {
          display: block;
          position: absolute;
          top: -10px;
          background-color: $white;
          width: 100%;
          height: 10px;
          content: '';
        }
      }

      &.options-on-top {
        button {
          border-top-left-radius: 0;
          border-top-right-radius: 0;
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
        }

        .dropdown-options {
          bottom: 25px;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;

          li {
            &:last-child {
              a {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
              }
            }

            &:first-child {
              a {
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
              }
            }
          }
        }
      }
    }
  }

  .input-error-text {
    position: relative;
    top: -14px;
    color: $error-color;
    font-size: 12px;
  }

  .error {
    .input {
      border-color: $error-color;
    }
  }
}


@media screen and (max-width: $screen-xs) {
  .dropdown-container {
    .dropdown {
      &.open {
        button {
          width: 100%;
        }

        .dropdown-options {
          width: 100%;
        }
      }
    }
  }
}
