.flex {
  display: flex;

  &.space-between-items {
    justify-content: space-between;
  }

  &.justify-items {
    justify-content: center;
  }

  &.place-right {
    justify-content: flex-end;
  }

  &.center-items {
    align-items: center;
  }

  &.wrap-items {
    flex-wrap: wrap;
  }

  &.auto-items {
    > * {
      flex: 1 1 auto;
    }
  }

  &.pad-items {
    > * {
      flex-basis: 0;
      flex-grow: 1;
      margin-bottom: 25px;
      margin-left: 30px;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  .auto {
    flex: 1 1 auto;
  }

  .block {
    flex: none;
  }
}
