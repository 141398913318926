@import "variables";
.step-form {
  margin: 0 auto;
  padding: 15px 0;
  min-height: 400px;

  &.step-10 {
    max-width: 970px;
    text-align: center;

    & p {
      line-height: 28px;
      font-size: 20px;
    }

    & b,
    .mailto {
      font-weight: bold;
    }

    & img {
      margin-bottom: 25px;
    }
  }
}

@media screen and (max-width: $screen-sm) {
  .step-form {
    &.step-10 {
      & p {
        font-size: 18px;
      }
    }
  }
}
