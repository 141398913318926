@import "variables";
.credit-summary {
  .summary-info {
    float: left;
    margin-bottom: 10px;
    width: 50%;
    font-size: 16px;

    &.lg {
      display: block;
    }

    &.sm {
      display: none;
    }
  }

  .description {
    float: left;
    width: 50%;
  }

  .value {
    float: left;
    padding-right: 28px;
    width: 50%;
    color: $base-gray;
    font-weight: 900;
  }
}

@media screen and (max-width: $screen-sm) {
  .credit-summary {
    .summary-info {
      width: 100%;

      &.lg {
        display: none;
      }

      &.sm {
        display: block;
      }

      .description {
        padding-right: 28px;
        text-align: right;
      }

      .value {
        padding-right: 0;
        text-align: left;
      }
    }
  }
}

@media screen and (max-width: $screen-xs) {
  .credit-summary {
    .summary-info {
      .description {
        text-align: left;
      }
    }
  }
}
