@import "variables";
@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.spinner-container {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 100000;

  &.table {
    display: inline-table;
    position: relative;
  }

  .spinner {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: $white;
    opacity: 0.5;
  }

  .loader {
    position: fixed;
    top: calc(50% - 60px);
    left: calc(50% - 60px);
    border: 16px solid  $blue;
    border-top-color: transparent;
    border-left-color: transparent;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
    z-index: 100001;
  }
}


@media screen and (max-width: $screen-sm) {
  .spinner-container {
    .loader {
      top: calc(50% - 30px);
      left: calc(50% - 30px);
      border: 8px solid $base-gray;
      border-top: 8px solid $blue;
      width: 60px;
      height: 60px;
    }
  }
}
